<template>
  <div class="section-container">
    <div class="user-section">
      <div class="btn-box">
        <div
          class="select-btn"
          :class="state.userType === 1 ? 'active' : 'inactive'"
          @click="handleChangeType(1, 1)"
        >
          개인
        </div>
        <div
          class="select-btn"
          :class="state.userType === 2 ? 'active' : 'inactive'"
          @click="handleChangeType(1, 2)"
        >
          기업
        </div>
      </div>
      <table class="user-table">
        <tbody>
          <tr>
            <th>* 소속</th>
            <td>
              <input
                type="text"
                v-model="state.company"
                required
                maxlength="30"
              />
            </td>
            <th>직책</th>
            <td>
              <input
                type="text"
                v-model="state.position"
                required
                maxlength="30"
              />
            </td>
          </tr>
          <tr>
            <th>* 성명</th>
            <td>
              <input type="text" v-model="state.name" required maxlength="30" />
            </td>
            <th>* 휴대전화</th>
            <td>
              <input
                type="text"
                v-model="state.phone_number"
                required
                maxlength="30"
              />
            </td>
          </tr>
          <tr>
            <th>* 이메일</th>
            <td colspan="3">
              <div class="mail">
                <input
                  type="text"
                  v-model="state.email_account"
                  required
                  maxlength="30"
                />
                <span>@</span>
                <input
                  type="text"
                  v-model="state.mail_service"
                  :disabled="state.selected_mail === '직접입력' ? false : true"
                  maxlength="30"
                />
                <select
                  style="width: 300px"
                  @change="handleSelectMailService()"
                  v-model="state.selected_mail"
                >
                  <option value="직접입력">직접입력</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="naver.com">naver.com</option>
                  <option value="hanmail.net">hanmail.net</option>
                  <option value="daum.net">daum.net</option>
                </select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btn-box">
        <div
          class="select-btn"
          :class="state.siteType === 1 ? 'active' : 'inactive'"
          @click="handleChangeType(2, 1)"
        >
          신축현장
        </div>
        <div
          class="select-btn"
          :class="state.siteType === 2 ? 'active' : 'inactive'"
          @click="handleChangeType(2, 2)"
        >
          리모델링 현장
        </div>
      </div>
      <table class="user-table">
        <tbody>
          <tr>
            <th>* 조명 선택</th>
            <td>
              <div class="label-wrap">
                <label>
                  <input
                    v-model="state.outdoor_lighting"
                    type="checkbox"
                    name="site_select"
                    value="경관조명"
                  />
                  경관조명
                </label>
                <label>
                  <input
                    v-model="state.indoor_lighting"
                    type="checkbox"
                    name="site_select"
                    value="실내조명"
                  />
                  실내조명
                </label>
              </div>
            </td>
            <th>* 공간 선택</th>
            <td>
              <div class="label-wrap">
                <label>
                  <input
                    type="checkbox"
                    v-model="state.public_place"
                    name="usage_select"
                    value="공공시설"
                  />
                  공공시설
                </label>
                <label>
                  <input
                    type="checkbox"
                    v-model="state.commercial_place"
                    name="usage_select"
                    value="상업공간"
                  />
                  상업공간
                </label>
                <label>
                  <input
                    type="checkbox"
                    v-model="state.other_place"
                    name="usage_select"
                    value="기타공간"
                  />
                  기타공간
                </label>
              </div>
            </td>
          </tr>
          <DaumPostCode
            @handleChangeAddress="handleChangeAddress"
            @changeDetailAddress="changeDetailAddress"
          />
          <tr>
            <th>* 공간 면적</th>
            <td colspan="3">
              <ul class="place_area">
                <li>
                  <span>평수</span>
                  <input
                    type="text"
                    v-model="state.square_footage"
                    maxlength="8"
                  />
                  <span>평</span>
                </li>
                <li>
                  <span>대지면적</span>
                  <input type="text" v-model="state.land_area" maxlength="8" />
                  <span>
                    m
                    <sup>2</sup>
                  </span>
                </li>
                <li>
                  <span>평수</span>
                  <input
                    type="text"
                    v-model="state.square_meter"
                    maxlength="8"
                  />
                  <span>
                    m
                    <sup>2</sup>
                  </span>
                </li>
                <span class="guide-span">
                  (3개의 부분 중 작성 가능한 것만 기입하시면 됩니다.)
                </span>
              </ul>
            </td>
          </tr>
          <tr>
            <th>공간의 상세 목적</th>
            <td colspan="3">
              <input
                type="text"
                v-model="state.place_purpose"
                placeholder="예시 : 카페, 호텔, 병원 등"
                style="width: 100%"
                maxlength="50"
              />
            </td>
          </tr>
          <tr>
            <th>문의 내용</th>
            <td colspan="3">
              <textarea
                v-model="state.inquiry"
                placeholder="추가 문의사항이 있으시면 입력해주세요. (1000자 제한)"
                maxlength="1000"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- 모바일용 복잡해서 따로 만듬 -->
  <div class="mobile-section">
    <div class="btn-box">
      <div
        class="select-btn"
        :class="state.userType === 1 ? 'active' : 'inactive'"
        @click="handleChangeType(1, 1)"
      >
        개인
      </div>
      <div
        class="select-btn"
        :class="state.userType === 2 ? 'active' : 'inactive'"
        @click="handleChangeType(1, 2)"
      >
        기업
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>*소속</span>
      </div>
      <div class="mobile-input-box">
        <input type="text" v-model="state.company" required maxlength="30" />
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>직책</span>
      </div>
      <div class="mobile-input-box">
        <input type="text" v-model="state.position" required maxlength="30" />
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>*성명</span>
      </div>
      <div class="mobile-input-box">
        <input type="text" v-model="state.name" required maxlength="30" />
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>*휴대전화</span>
      </div>
      <div class="mobile-input-box">
        <input
          type="text"
          v-model="state.phone_number"
          required
          maxlength="30"
        />
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>*이메일</span>
      </div>
      <div class="mobile-input-box">
        <div class="mobile-email-account-box">
          <input
            type="text"
            v-model="state.email_account"
            required
            maxlength="30"
            class="email-1"
          />
          <span>&nbsp;@</span>
        </div>
        <div class="mobile-email-domain-box">
          <input
            type="text"
            v-model="state.mail_service"
            :disabled="state.selected_mail === '직접입력' ? false : true"
            maxlength="30"
          />
          <select
            @change="handleSelectMailService()"
            v-model="state.selected_mail"
          >
            <option value="직접입력">직접입력</option>
            <option value="gmail.com">gmail.com</option>
            <option value="naver.com">naver.com</option>
            <option value="hanmail.net">hanmail.net</option>
            <option value="daum.net">daum.net</option>
          </select>
        </div>
      </div>
    </div>
    <div class="btn-box" style="margin-top: 60px">
      <div
        class="select-btn"
        :class="state.siteType === 1 ? 'active' : 'inactive'"
        @click="handleChangeType(2, 1)"
      >
        신축현장
      </div>
      <div
        class="select-btn"
        :class="state.siteType === 2 ? 'active' : 'inactive'"
        @click="handleChangeType(2, 2)"
      >
        리모델링 현장
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>*조명 선택</span>
      </div>
      <div class="label-wrap">
        <label>
          <input
            v-model="state.outdoor_lighting"
            type="checkbox"
            name="site_select"
            value="경관조명"
          />
          경관조명
        </label>
        <label>
          <input
            v-model="state.indoor_lighting"
            type="checkbox"
            name="site_select"
            value="실내조명"
          />
          실내조명
        </label>
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>*공간 선택</span>
      </div>
      <div class="label-wrap">
        <label>
          <input
            type="checkbox"
            v-model="state.public_place"
            name="usage_select"
            value="공공시설"
          />
          공공시설
        </label>
        <label>
          <input
            type="checkbox"
            v-model="state.commercial_place"
            name="usage_select"
            value="상업공간"
          />
          상업공간
        </label>
        <label>
          <input
            type="checkbox"
            v-model="state.other_place"
            name="usage_select"
            value="기타공간"
          />
          기타공간
        </label>
      </div>
    </div>
    <div style="margin-top: 30px; padding-left: 5%">
      <div class="mobile-label-box">
        <span>*현장 주소</span>
      </div>
      <DaumPostCode
        @handleChangeAddress="handleChangeAddress"
        @changeDetailAddress="changeDetailAddress"
      />
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span
          >*공간 면적 (3개의 부분 중 작성 가능한 것만 기입하시면 됩니다.)</span
        >
      </div>
      <ul class="place_area">
        <li>
          <span>평수</span>
          <input type="text" v-model="state.square_footage" maxlength="8" />
          <span>평</span>
        </li>
        <li>
          <span>면적</span>
          <input type="text" v-model="state.land_area" maxlength="8" />
          <span>
            m
            <sup>2</sup>
          </span>
        </li>
        <li>
          <span>평수</span>
          <input type="text" v-model="state.square_meter" maxlength="8" />
          <span>
            m
            <sup>2</sup>
          </span>
        </li>
      </ul>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>공간의 상세 목적</span>
      </div>
      <div class="mobile-input-box">
        <input
          type="text"
          v-model="state.place_purpose"
          required
          maxlength="30"
          placeholder="예시 : 카페, 호텔, 병원 등"
        />
      </div>
    </div>
    <div class="mobile-field">
      <div class="mobile-label-box">
        <span>문의 내용</span>
      </div>
      <div class="mobile-input-box">
        <textarea
          v-model="state.inquiry"
          placeholder="추가 문의사항이 있으시면 입력해주세요. (1000자 제한)"
          maxlength="1000"
        ></textarea>
      </div>
    </div>
  </div>
  <!-- 모바일 영역 끝 -->
  <div class="btn-section">
    <button class="send-btn" @click="requestSendEmail()">SEND</button>
  </div>
  <div v-if="state.spinner" class="modal">
    <img class="spinner" src="@/assets/images/utils_images/spinner.gif" />
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import DaumPostCode from "@/components/utils/PostCode.vue";
import emailjs from "emailjs-com";
import { useRouter } from "vue-router";
export default {
  components: {
    DaumPostCode,
  },
  methods: {
    changeDetailAddress(addr2) {
      this.state.detail_address = addr2;
    },
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      userType: 1, // 1: 개인, 2: 기업
      company: "", // 소속
      position: "", // 직책
      name: "", // 성명
      phone_number: "", // 전화번호
      selected_mail: "직접입력",
      email_account: "", // 이메일 계정
      mail_service: "", // 이미일 주소
      siteType: 1, // 1: 신축현장, 2: 리모델링 현장
      outdoor_lighting: false, // 경관조명
      indoor_lighting: false, // 실내조명
      public_place: false, // 공공시설
      commercial_place: false, // 상업공간
      other_place: false, // 기타공간
      main_address: "", // 도로명 주소
      zip_code: "", // 우편 번호
      detail_address: "", // 기타 주소
      square_footage: "", // 평수
      land_area: "", // 대지면적
      square_meter: "", // 평방미터
      place_purpose: "", // 공간의 상세 목적
      inquiry: "", // 문의내용
      spinner: false,
    });

    const handleChangeType = (type_category, type_number) => {
      if (type_category === 1) {
        state.userType = type_number;
      } else {
        state.siteType = type_number;
      }
    };

    const handleChangeAddress = (zip_code, main_address) => {
      state.zip_code = zip_code;
      state.main_address = main_address;
    };

    const handleSelectMailService = () => {
      let str = state.selected_mail;
      if (str === "직접입력") {
        state.mail_service = "";
      } else {
        state.mail_service = str;
      }
    };

    const requestSendEmail = () => {
      if (!state.company) return alert("소속을 입력해주세요.");
      if (!state.name) return alert("성명을 입력해주세요.");
      if (!state.phone_number) return alert("연락처를 입력해주세요.");
      if (!state.email_account || !state.mail_service)
        return alert("이메일을 입력해주세요.");
      if (!state.outdoor_lighting && !state.indoor_lighting)
        return alert("최소 하나 이상의 조명선택을 체크하셔야 합니다.");
      if (!state.public_place && !state.commercial_place && !state.other_place)
        return alert("최소 하나 이상의 공간선택을 체크하셔야 합니다.");
      if (!state.zip_code || !state.main_address)
        return alert("주소를 다시 확인해 주세요.");
      if (!state.square_footage && !state.land_area && !state.square_meter)
        return alert("최소 하나 이상의 공간면적을 기입하셔야 합니다.");
      if (!confirm("문의 요청 하시겠습니까?")) return;
      state.spinner = true;
      let user_type = "";
      if (state.userType === 1) {
        user_type = "개인";
      } else {
        user_type = "기업";
      }
      let site_type = "";
      if (state.siteType === 1) {
        site_type = "신축현장";
      } else {
        site_type = "리모델링 현장";
      }
      let lighting = [];
      if (state.outdoor_lighting) {
        lighting.push("경관조명");
      }
      if (state.indoor_lighting) {
        lighting.push("실내조명");
      }
      let place = [];
      if (state.public_place) {
        place.push("공공시설");
      }
      if (state.commercial_place) {
        place.push("상업공간");
      }
      if (state.other_place) {
        place.push("기타공간");
      }
      let area = [];
      if (state.square_footage) {
        area.push(` 평수 [${state.square_footage}]평`);
      }
      if (state.land_area) {
        area.push(` 대지면적 [${state.land_area}]m2`);
      }
      if (state.square_meter) {
        area.push(` 평수 [${state.square_meter}]m2`);
      }
      const payload = {
        user_type: user_type,
        company: state.company,
        position: state.position,
        name: state.name,
        phone_number: state.phone_number,
        email: `${state.email_account}@${state.mail_service}`,
        site_type: site_type,
        lighting: lighting,
        place: place,
        address: `${state.zip_code} ${state.main_address} ${state.detail_address}`,
        area: area,
        place_purpose: state.place_purpose,
        inquiry: state.inquiry,
      };
      emailjs
        .send(
          "service_c2jmobk",
          "template_f9opghl",
          payload,
          "fvgGS1NVWqzzXxG-Z"
        )
        .then(
          (res) => {
            if (res.status === 200) {
              alert("문의가 성공적으로 완료되었습니다.");
            } else {
              alert(
                "문의가 정상적으로 이루어지지 않았습니다. 다시 시도하시거나 다른 방법으로 연락해 주세요."
              );
            }
            state.spinner = false;
            router.push({ name: "main" });
          },
          (error) => {
            alert("알수 없는 오류가 발생하였습니다. 본사로 문의해 주세요.");
            console.log(error);
            state.spinner = false;
            router.push({ name: "main" });
          }
        );
    };

    return {
      state,
      ...toRefs(state),
      handleChangeType,
      handleSelectMailService,
      handleChangeAddress,
      requestSendEmail,
    };
  },
};
</script>

<style scoped>
.section-container {
  display: flex;
  z-index: 99;
}
.btn-box {
  display: flex;
  margin-top: 100px;
}
.select-btn {
  margin-right: 30px;
  width: 240px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.btn-box .active {
  color: #fff;
  background-color: #ef9600;
  border: none;
}
.btn-box .inactive {
  color: #666666;
  background-color: #fff;
  border: 1px solid #ddd;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: initial;
}
tr {
  display: table-row;
  width: 100%;
}
th,
td {
  display: table-cell;
  vertical-align: inherit;
}

.user-table {
  margin-top: 40px;
  border-top: 1px solid #ddd;
}
.user-table th,
.user-table td {
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}
.user-table th {
  width: 185px;
  background: #f7f7f7;
  font-size: 16px;
  color: #111;
  font-weight: 300;
}
.user-table input[type="text"] {
  width: 390px;
  height: 40px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding-left: 10px;
  outline: none;
}
.mail input[type="text"] {
  width: 300px;
}
.user-table select {
  width: 300px;
  height: 40px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding-left: 10px;
  outline: none;
}
.mail span {
  margin-left: 10px;
  margin-right: 10px;
}
.mail select {
  margin-left: 20px;
}
.label-wrap {
  width: 390px;
}
.label-wrap label {
  margin-right: 10px;
}
.label-wrap > label > input {
  transform: translateY(1.2px);
}
.place_area {
  display: flex;
  margin: 0;
}
.place_area li {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.place_area li input[type="text"] {
  margin-left: 15px;
  margin-right: 10px;
  width: 100px;
}
.guide-span {
  margin-left: 20px;
  line-height: 40px;
  font-size: 14px;
}
textarea {
  width: 1103px;
  height: 221px;
  padding: 10px 15px;
  box-sizing: border-box;
  outline: none;
  border-color: #ddd;
  vertical-align: top;
}

.btn-section {
  display: flex;
  justify-content: center;
}
.send-btn {
  border: 0;
  margin-top: 60px;
  width: 240px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  background-color: #555555;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.modal {
  position: fixed;
  width: 100%;
  height: 226.5%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-section {
  display: none;
}

@media (max-width: 600px) {
  .user-section {
    display: none;
  }
  .mobile-section {
    display: block;
  }
  .btn-box {
    width: 100%;
    padding-left: 5%;
  }
  .select-btn {
    width: 40%;
    height: 30px;
    line-height: 30px;
  }
  .mobile-field {
    padding-left: 5%;
    margin-top: 30px;
  }
  .mobile-label-box {
    font-size: 13px;
    color: #777;
  }
  .mobile-input-box {
    width: 90%;
  }
  .mobile-input-box input {
    width: 100%;
    outline: none;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .mobile-email-account-box {
    display: flex;
  }
  .mobile-email-account-box input {
    width: 60%;
  }
  .mobile-email-account-box span {
    line-height: 40px;
    font-size: 20px;
    color: #777;
  }
  .mobile-email-domain-box {
    display: flex;
    margin-top: 10px;
  }
  .mobile-email-domain-box input {
    width: 60%;
  }
  .mobile-email-domain-box select {
    width: 36%;
    outline: none;
    border-radius: 0;
    margin-left: 10px;
  }
  .place_area {
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    color: #777;
  }
  .place_area li {
    display: flex;
    align-items: center;
    margin-right: 0;
  }
  .place_area li input[type="text"] {
    margin-left: 0;
    margin-right: 0;
    width: 40%;
  }
  .mobile-input-box textarea {
    width: 100%;
  }
  .label-wrap label {
    color: #777;
  }
}
</style>
