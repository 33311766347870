<template>
  <div class="section-container">
    <div class="tab-container">
      <div
        class="tab-item"
        @click="handleActive(0)"
        :class="{
          active: state.tabNum === 0,
        }"
      >
        ALL
      </div>
      <span>|</span>
      <div
        class="tab-item"
        @click="handleActive(1)"
        :class="{
          active: state.tabNum === 1,
        }"
      >
        Indoor
      </div>
      <span>|</span>
      <div
        class="tab-item"
        @click="handleActive(2)"
        :class="{
          active: state.tabNum === 2,
        }"
      >
        Outdoor
      </div>
    </div>
    <div class="works-container">
      <div
        v-for="(tmp, idx) in state.worksList.slice().reverse()"
        :key="idx"
        class="works-item"
        @click="handleSelectOneWorks(tmp.works_no)"
      >
        <img :src="tmp.works_img_url" />
        <div class="works-item-name-box">
          <p>{{ tmp.works_name_kor }}</p>
          <span>{{ tmp.works_name_eng }}</span>
        </div>
        <div class="mobile-name-box">
          <p>{{ tmp.works_name_kor }}</p>
          <span>{{ tmp.works_name_eng }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { worksData } from "@/data/worksData";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      tabNum: 0,
      worksList: [],
    });

    const handleActive = (num) => {
      state.tabNum = num;
      if (num === 0) {
        state.worksList = worksData;
      } else {
        state.worksList = worksData.filter((tmp) => tmp.works_type === num);
      }
    };

    const handleSelectOneWorks = (works_no) => {
      router.push({ name: "work_one", query: { no: works_no } });
    };

    onMounted(() => {
      state.worksList = worksData;
    });

    return {
      state,
      ...toRefs(state),
      handleActive,
      handleSelectOneWorks,
    };
  },
};
</script>

<style scoped>
.tab-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding: 60px 0;
  font-size: 18px;
}
.tab-container span {
  color: #ddd;
}
.tab-item {
  margin: 0 30px;
  cursor: pointer;
}
.tab-item:hover {
  color: #ef9600;
}
.active {
  color: #ef9600;
  text-decoration: underline;
  font-weight: 600;
}

.works-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 40px;
}
.works-item {
  position: relative;
  cursor: pointer;
}
.works-item img {
  width: 100%;
  height: auto;
  transition: all 0.5s;
}
.works-item-name-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease-in;
  text-align: center;
}
.works-item-name-box p {
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  margin: 0;
}
.works-item-name-box span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
/* .works-item:hover {
  background-color: #000;
} */
.works-item:hover img {
  filter: brightness(0.5);
}
.works-item:hover .works-item-name-box {
  opacity: 1;
}

.mobile-name-box {
  display: none;
}
.mobile-name-box p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.mobile-name-box span {
  font-size: 14px;
  color: #aaa;
}

@media (max-width: 600px) {
  .works-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 60px 0;
    padding: 0 20px;
  }
  .mobile-name-box {
    display: block;
  }
  .works-item-name-box {
    display: none;
  }
  .works-item:hover img {
    filter: none;
  }
}
</style>
