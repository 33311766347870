<template>
  <div class="section-container">
    <div class="news-container">
      <div
        v-for="(tmp, idx) in state.newsList.slice().reverse()"
        :key="idx"
        class="news-item"
        @click="handleSelectOneNews(tmp.news_no)"
      >
        <img :src="tmp.news_thumbs_url" />
        <div class="news-text-box">
          <p class="news-title">{{ tmp.news_title }}</p>
          <p class="news-summary">{{ tmp.news_summary }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { newsData } from "@/data/newsData";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      newsList: [],
    });

    const handleSelectOneNews = (news_no) => {
      router.push({ name: "news_one", query: { no: news_no } });
    };

    onMounted(() => {
      state.newsList = newsData;
    });

    return {
      state,
      ...toRefs(state),
      handleSelectOneNews,
    };
  },
};
</script>

<style scoped>
.section-container {
  width: 100%;
}
.news-container {
  display: grid;
  position: relative;
  margin-top: 100px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}
.news-item {
  height: 100%;
  cursor: pointer;
}
.news-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.news-title {
  font-size: 25px;
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 20px;
}
.news-summary {
  color: #666666;
  line-height: 26px;
  letter-spacing: -0.025em;
  word-break: keep-all;
}
.news-item:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .news-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px;
  }
}
</style>
