<template>
  <div class="main-container">
    <div class="section-container">
      <transition>
        <div v-if="state.section === 1" class="section section-1"></div>
      </transition>
      <transition>
        <div v-if="state.section === 2" class="section section-2"></div>
      </transition>
      <transition>
        <div v-if="state.section === 3" class="section section-3"></div>
      </transition>
      <transition>
        <div v-if="state.section === 4" class="section section-4"></div>
      </transition>
      <transition>
        <div v-if="state.section === 5" class="section section-5"></div>
      </transition>
      <div class="btn-box">
        <div>
          <button @click="handleChangeSection(1)">
            <div class="btn-items">
              <span :class="state.section === 1 ? 'text-active' : ''">
                SHILLA STAY SEOBUSAN
              </span>
              <img
                v-if="state.section !== 1"
                src="../../assets/images/main_images/vis-dots.png"
                class="img-inactive"
              />
              <img
                v-if="state.section === 1"
                src="../../assets/images/main_images/vis-dots-active.png"
                class="img-active"
              />
            </div>
          </button>
        </div>
        <div>
          <button @click="handleChangeSection(2)">
            <div class="btn-items">
              <span :class="state.section === 2 ? 'text-active' : ''">
                BUSAN X THE SKY
              </span>
              <img
                v-if="state.section !== 2"
                src="../../assets/images/main_images/vis-dots.png"
                class="img-inactive"
              />
              <img
                v-if="state.section === 2"
                src="../../assets/images/main_images/vis-dots-active.png"
                class="img-active"
              />
            </div>
          </button>
        </div>
        <div>
          <button @click="handleChangeSection(3)">
            <div class="btn-items">
              <span :class="state.section === 3 ? 'text-active' : ''">
                SUNGWON MEDICAL BUILDING
              </span>
              <img
                v-if="state.section !== 3"
                src="../../assets/images/main_images/vis-dots.png"
                class="img-inactive"
              />
              <img
                v-if="state.section === 3"
                src="../../assets/images/main_images/vis-dots-active.png"
                class="img-active"
              />
            </div>
          </button>
        </div>
        <div>
          <button @click="handleChangeSection(4)">
            <div class="btn-items">
              <span :class="state.section === 4 ? 'text-active' : ''">
                HAEUNDAE BEACH GOLF & RESORT
              </span>
              <img
                v-if="state.section !== 4"
                src="../../assets/images/main_images/vis-dots.png"
                class="img-inactive"
              />
              <img
                v-if="state.section === 4"
                src="../../assets/images/main_images/vis-dots-active.png"
                class="img-active"
              />
            </div>
          </button>
        </div>
        <div>
          <button @click="handleChangeSection(5)">
            <div class="btn-items">
              <span :class="state.section === 5 ? 'text-active' : ''">
                GIJANG CAFE
              </span>
              <img
                v-if="state.section !== 5"
                src="../../assets/images/main_images/vis-dots.png"
                class="img-inactive"
              />
              <img
                v-if="state.section === 5"
                src="../../assets/images/main_images/vis-dots-active.png"
                class="img-active"
              />
            </div>
          </button>
        </div>
      </div>
      <div class="vertical-line"></div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-core";
export default {
  setup() {
    const state = reactive({
      section: 1,
      section_switch: false,
    });

    const handleChangeSection = (section_number) => {
      if (state.section_switch) return;
      state.section_switch = true;
      state.section = section_number;
      setTimeout(() => {
        state.section_switch = false;
      }, 1000);
    };

    const intervalSection = () => {
      if (state.section_switch) return;
      state.section_switch = true;
      if (state.section === 5) {
        state.section = 0;
      }
      state.section++;
      setTimeout(() => {
        state.section_switch = false;
      }, 1000);
    };

    onMounted(() => {
      setInterval(intervalSection, 7000);
      document.body.style.backgroundColor = "black";
    });

    onUnmounted(() => {
      clearInterval(intervalSection);
      document.body.style.backgroundColor = "white";
    });

    return {
      state,
      handleChangeSection,
    };
  },
};
</script>

<style scoped>
.main-container {
  overflow: hidden;
}
.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.section::before {
  content: "";
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  position: absolute;
  right: 0;
  height: 100%;
  width: 25%;
}

.section-1 {
  background: url("../../assets/images/main_images/ambience_main_1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-2 {
  background: url("../../assets/images/main_images/ambience_main_2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-3 {
  background: url("../../assets/images/main_images/ambience_main_3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-4 {
  background: url("../../assets/images/main_images/ambience_main_4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-5 {
  background: url("../../assets/images/main_images/ambience_main_5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 36px;
  z-index: 102;
}
.btn-box button {
  outline: none;
  border: none;
  margin-top: 30px;
  background-color: inherit;
  color: white;
  width: 550px;
  text-align: right;
  font-size: 15px;
}
.btn-box button:hover {
  cursor: pointer;
}
.btn-box .img-inactive {
  margin-left: 30px;
  width: 7px;
  height: 7px;
}
.btn-box .img-active {
  width: 54px;
  height: 30px;
  transform: translate(11px, 6px);
}
.btn-text-box {
  width: 200px;
  text-align: right;
  background-color: transparent;
}
.text-active {
  font-size: 24px;
  font-weight: 600;
}

.vertical-line {
  position: fixed;
  height: 100vh;
  top: 0;
  right: 45px;
  z-index: 101;
  border-right: solid white 0.01px;
  opacity: 0.5;
}

.v-enter-from {
  transform: translateY(100%);
}
.v-enter-active {
  transition: transform 1s ease;
}
.v-enter-top {
  transform: translateY(0);
}
.v-leave-from {
  transform: translateY(0);
}
.v-leave-active {
  transition: transform 1s ease;
}
.v-leave-to {
  transform: translateY(-100%);
}

@media (max-width: 600px) {
  .main-container {
    width: 100%;
    height: calc(100%);
  }
  .vertical-line {
    display: none;
  }
  .btn-box {
    top: 75%;
    right: 15px;
  }
  .btn-box button {
    line-height: 14px;
    font-size: 14px;
    width: 400px;
  }
  .text-active {
    font-size: 14px;
  }
  .section {
    background-size: cover;
    background-position: center;
  }
  .section::before {
    content: "";
    background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    position: absolute;
    right: 0;
    height: 101%;
    width: 100%;
  }
}
</style>
