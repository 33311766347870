<template>
  <div class="section-container">
    <!-- 회사 설명 -->
    <div class="container-common description-container">
      <img
        class="description-image"
        :src="require('../../assets/images/about_images/about-top-img.jpg')"
      />
      <div class="description-content">
        <div>
          <div class="main-description">
            <h1>Ambience, The True Light</h1>
            <p>빛을 설계하는 앰비언스는 자연의 빛에서 그 영감을 얻습니다.</p>
            <p>
              자연은 그 자체로서 완벽하고 아름다운 빛을 내며,이는 조명 설계를
              진행하는 동안 가장 중요한 기준이자 영감의 근원입니다.
            </p>
            <p>
              앰비언스는 빛을 다루는 기업으로서 가장 알맞은 빛을 가 장 알맞은
              장소와 시간에 제공함으로써 빛과 공간이 지닐 수 있는 그 가치를
              극대화합니다.
            </p>
            <p>
              새로운 브랜드를 발굴하고 브랜드의 가치를 극대화하며, 조명 부분에
              대한 지속 가능한 비전을 제시합니다.
            </p>
            <p>
              또한 앰비언스를 통해 브랜드를 경험하시는 모든 분들께 특별한 감동을
              드리기 위해 노력하고 있습니다.
            </p>
          </div>
          <div>
            <div class="sub-description">
              <h4>Client Experience</h4>
              <p>
                앰비언스는 클라이언트의 경험을 통해 세계적인 브랜드와 본질적인
                가치를 전달합니다.
              </p>
            </div>
            <div class="sub-description">
              <h4>Value of Space</h4>
              <p>
                혁신적 설계를 통해 공간의 가치를 상승시켜 기대 이상의 만족감을
                드립니다.
              </p>
            </div>
            <div class="sub-description">
              <h4>True Ambience</h4>
              <p>
                앰비언스는 직접적인 조명보다는 간접적인 조명설계를 통해 신체 및
                심리적 안정감을 제공하는 것을 궁극적 목표로 하고 있습니다.
              </p>
            </div>
            <div class="sub-description" style="margin: 0">
              <h4>Economic Rationality</h4>
              <p>
                가장 합리적인 가격에 최상의 서비스를 제공해드리겠다는 확고한
                앰비언스의 진정성이자 의지입니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 패밀리 사이트 -->
    <!-- <div class="container-common link-container">
      <h1>Family site</h1>
      <div class="link-box">
        <a href="https://blog.naver.com/ambience_lighting" target="_blank">
          <button>AMBIENCE blog</button>
        </a>
        <a href="" target="_blank">
          <button>AMBIENCE instagram</button>
        </a>
        <a href="" target="_blank">
          <button>AMBIENCE shop</button>
        </a>
      </div>
    </div> -->
  </div>
  <div class="mobile-description-container">
    <h1 class="mobile-main-description-title">Ambience, The True Light</h1>
    <br />
    <div class="mobile-main-description-box">
      <p class="mobile-main-description-content">
        빛을 설계하는 앰비언스는 자연의 빛에서 그 영감을 얻습니다.
      </p>
      <p class="mobile-main-description-content">
        자연은 그 자체로서 완벽하고 아름다운 빛을 내며,이는 조명 설계를 진행하는
        동안 가장 중요한 기준이자 영감의 근원입니다.
      </p>
      <p class="mobile-main-description-content">
        앰비언스는 빛을 다루는 기업으로서 가장 알맞은 빛을 가장 알맞은 장소와
        시간에 제공함으로써 빛과 공간이 지닐 수 있는 그 가치를 극대화합니다.
      </p>
      <p class="mobile-main-description-content">
        새로운 브랜드를 발굴하고 브랜드의 가치를 극대화하며, 조명 부분에 대한
        지속 가능한 비전을 제시합니다.
      </p>
      <p class="mobile-main-description-content">
        또한 앰비언스를 통해 브랜드를 경험하시는 모든 분들께 특별한 감동을
        드리기 위해 노력하고 있습니다.
      </p>
    </div>
    <div class="mobile-sub-description-title">Client Experience</div>
    <p class="mobile-sub-description-content">
      앰비언스는 클라이언트의 경험을 통해 세계적인 브랜드와 본질적인 가치를
      전달합니다.
    </p>
    <div class="mobile-sub-description-title">Value of Space</div>
    <p class="mobile-sub-description-content">
      혁신적 설계를 통해 공간의 가치를 상승시켜 기대 이상의 만족감을 드립니다.
    </p>
    <div class="mobile-sub-description-title">True Ambience</div>
    <p class="mobile-sub-description-content">
      앰비언스는 직접적인 조명보다는 간접적인 조명설계를 통해 신체 및 심리적
      안정감을 제공하는 것을 궁극적 목표로 하고 있습니다.
    </p>
    <div class="mobile-sub-description-title">Economic Rationality</div>
    <p class="mobile-sub-description-content">
      가장 합리적인 가격에 최상의 서비스를 제공해드리겠다는 확고한 앰비언스의
      진정성이자 의지입니다.
    </p>
  </div>
  <!-- 브랜드사 소개 -->
  <!-- <div class="container-common brand-container">
    <h1>Our brands</h1>
    <div class="brand-box">
      <div class="brand-card">
        <img
          :src="
            require('../../assets/images/about_images/brand_images/brand_list_img01.jpg')
          "
        />
        <div class="brand-text">
          <span>루이스폴센</span>
          <span class="brand-number">01</span>
        </div>
        <p>DENMARK</p>
      </div>
    </div>
  </div> -->
  <!-- 활동 영역 -->
  <div class="container-common activity-container">
    <h1 class="text-option">Our activities</h1>
    <h2 class="text-option">Lighting design - Indoor</h2>
    <div class="indoor">
      <div class="activity-item">
        <img
          :src="require('../../assets/images/about_images/about-list-img1.jpg')"
        />
        <p>3D simulation</p>
      </div>
      <div class="activity-item">
        <img
          :src="require('../../assets/images/about_images/about-list-img2.jpg')"
        />
        <p>Illuminance calculation</p>
      </div>
      <div class="activity-item">
        <img
          :src="require('../../assets/images/about_images/about-list-img3.jpg')"
        />
        <p>Lighting specifications</p>
      </div>
    </div>
    <h2 class="text-option">Lighting design - Outdoor</h2>
    <div class="outdoor">
      <div class="activity-item">
        <img
          :src="require('../../assets/images/about_images/about-list-img4.jpg')"
        />
        <p>3D simulation</p>
      </div>
      <div class="activity-item">
        <img
          :src="require('../../assets/images/about_images/about-list-img5.jpg')"
        />
        <p>llluminance calculation · Luminance measurement</p>
      </div>
      <div class="activity-item">
        <img
          :src="require('../../assets/images/about_images/about-list-img6.jpg')"
        />
        <p>Lighting specifications</p>
      </div>
    </div>
    <h2 class="activity-brand-text text-option">
      Brands lighting <span>- National Assembly Busan Library by Ambience</span>
    </h2>
    <div class="brand-container">
      <div class="brand-box">
        <div class="brand-card">
          <img
            :src="
              require('../../assets/images/works_images/details/20220519041604_mcvXXHFknh3C9KtRE6PH6HzLyvY31u.jpg')
            "
          />
          <div class="brand-text">
            <span>루이스폴센</span>
            <span class="brand-number">01</span>
          </div>
          <p>DENMARK</p>
        </div>
      </div>
      <div class="brand-box">
        <div class="brand-card">
          <img
            :src="
              require('../../assets/images/works_images/details/20220519041543_Ojivhfc9kJNWesfIJyyWbpQ0inulg7.jpg')
            "
          />
          <div class="brand-text">
            <span>베르판</span>
            <span class="brand-number">02</span>
          </div>
          <p>DENMARK</p>
        </div>
      </div>
      <div class="brand-box">
        <div class="brand-card">
          <img
            :src="
              require('../../assets/images/works_images/details/20220519041203_N2Hx5sQgLStSyp39xjJ42x5S4FRDgQ.jpg')
            "
          />
          <div class="brand-text">
            <span>루체플랜</span>
            <span class="brand-number">03</span>
          </div>
          <p>ITALY</p>
        </div>
      </div>
    </div>
    <div class="contact-container">
      <div @click="handleContact()" class="contact-btn">CONTACT</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    const handleContact = () => {
      router.push("/contact");
    };
    // const brandList = [
    //   { brand_no: 1, brand_name: "루이스폴센", brand_area: "DENMARK" },
    // ];
    return {
      handleContact,
    };
  },
};
</script>

<style scoped>
h1,
h4,
p {
  margin: 0;
}
h1,
h4 {
  color: #333;
  font-weight: 600;
}
.section-container {
  width: 100%;
}
.container-common {
  margin-top: 100px;
}
.description-container {
  display: flex;
  width: 100%;
}
.description-image {
  width: 30%;
  height: 720px;
  padding-right: 60px;
}
.description-content {
  text-align: start;
  display: flex;
  align-items: center;
  width: 50%;
  height: 720px;
  letter-spacing: -0.025em;
  /* word-break: keep-all; */
  line-height: 30px;
}
.main-description {
  width: 100%;
  margin-bottom: 40px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
}
.main-description h1 {
  margin-top: 0;
  margin-bottom: 40px;
}
.main-description p {
  font-size: 16px;
  line-height: 34px;
}
.sub-description {
  letter-spacing: 0.5px;
  padding-bottom: 20px;
}

.link-box {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  margin-top: 60px;
  width: 100%;
}
.link-box button {
  border: 0;
  width: 100%;
  background-color: #746a57;
  color: #fff;
  line-height: 60px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.025em;
}
.brand-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.brand-card {
  width: 440px;
}
.brand-card img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}
.brand-text {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  color: #222;
  letter-spacing: -0.5px;
  font-weight: bold;
}
.brand-number {
  color: #746a57;
  line-height: 50px;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -0.025em;
  transform: translateY(-5px);
}
.brand-card p {
  line-height: 18px;
  color: #999;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  transform: translateY(-8px);
}

.indoor,
.outdoor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  margin-bottom: 60px;
}
.activity-item {
  width: 440px;
  text-align: center;
}
.activity-item img {
  width: 100%;
  height: 100%;
}

.contact-container {
  display: flex;
  justify-content: center;
}
.contact-btn {
  margin-top: 60px;
  width: 240px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  background-color: #ef9600;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.activity-brand-text span {
  font-size: 16px;
  font-weight: 600;
}
.mobile-description-container {
  display: none;
}

@media (max-width: 600px) {
  .description-container {
    display: none;
  }
  .mobile-description-container {
    display: block;
    width: 100%;
    margin-top: 100px;
  }
  .indoor,
  .outdoor {
    place-items: center;
    grid-template-columns: repeat(1, 1fr);
  }
  .activity-item {
    width: 90%;
    margin: 30px 0;
  }
  .brand-container {
    place-items: center;
    grid-template-columns: repeat(1, 1fr);
  }
  .brand-box {
    padding-left: 5%;
    margin: 30px 0;
  }
  .brand-card {
    width: 95%;
  }
  .brand-card img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .activity-brand-text span {
    display: none;
  }
  .mobile-main-description-title {
    font-size: 28px;
    text-align: center;
  }
  .mobile-main-description-box {
    padding-left: 5%;
    width: 90%;
  }
  .mobile-main-description-content {
    font-size: 14px;
    margin-top: 30px;
    text-align: center;
    word-break: keep-all;
    white-space: pre-line;
  }
  .mobile-sub-description-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 10px;
  }
  .mobile-sub-description-content {
    width: 80%;
    padding-left: 10%;
    font-size: 14px;
    text-align: center;
    word-break: keep-all;
    white-space: pre-line;
  }
  .text-option {
    padding-left: 5%;
  }
}
</style>
