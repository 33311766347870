<template>
  <div class="container">
    <h3>{{ state.works_one.works_name_kor }}</h3>
    <h3>{{ state.works_one.works_name_eng }}</h3>
    <br />
    <p>{{ state.works_one.works_year }}</p>
    <p>Prime contractor : {{ state.works_one.works_contractor }}</p>
    <p>Site : {{ state.works_one.works_site }}</p>
  </div>
  <div class="image-container">
    <div
      v-for="(tmp, idx) in state.works_one.works_detail_img"
      :key="idx"
      class="image-box"
    >
      <img :src="tmp" />
    </div>
  </div>
  <div class="description-container">
    <p>{{ state.works_one.works_description }}</p>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { worksData } from "@/data/worksData";
export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      works_no: route.query.no,
      works_one: {
        works_name_kor: "",
        works_name_eng: "",
        works_year: 0,
        works_contractor: "",
        works_site: "",
        works_detail_img: [],
        works_description: "",
      },
    });

    onMounted(() => {
      state.works_one = worksData[state.works_no - 1];
    });
    return {
      state,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin-top: 200px;
}
h3,
p {
  margin: 0;
}
.image-container {
  width: 100%;
}
.image-box {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
.image-box img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}
.description-container {
  max-width: 80%;
  padding-left: 10%;
}
.description-container p {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
}

@media (max-width: 600px) {
  .container {
    padding: 0 5%;
    margin-top: 100px;
  }
  .image-box img {
    width: 90%;
    height: 90%;
  }
  .description-container {
    max-width: 90%;
    padding-left: 5%;
  }
}
</style>
