<template>
  <div class="antiemail-container">
    <DocHeaderPage />
    <div class="antiemail">
      <p class="intro">
        본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의<br />
        기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를 위반시
        통신망법에 의해<br />
        형사처벌 됨을 유념하시기 바랍니다.
      </p>
      <p class="law">제50조의2(전자우편주소의 무단 수집행위 등 금지)</p>
      <p class="intro">
        1. 누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이
      </p>
      <p class="law-content">
        인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램이나 그
        밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니 된다.
      </p>
      <p class="intro">2. 누구든지 제1항을 위반하여</p>
      <p class="law-content">
        수집된 전자우편주소를 판매, 유통 하여서는 아니 된다.
      </p>
      <p class="intro">3. 누구든지 제1항과 2항에 따라</p>
      <p class="law-content">
        수집, 판매 및 유통이 금지된 전자우편주소임을 알면서 이를 정보 전송에
        이용하여서는 아니 된다.
      </p>
      <br />
      <p class="intro">
        제50조의2 규정에 의하여 1천만원 이 하의 벌금형에 처해집니다.
      </p>
    </div>
  </div>
</template>

<script>
import DocHeaderPage from "@/components/doc/DocHeaderPage.vue";
export default {
  components: {
    DocHeaderPage,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.antiemail-container {
  margin-top: 140px;
}
.intro {
  margin: 20px 0;
  font-size: 14px;
  font-weight: 700;
}
.law {
  padding: 12px;
  padding-left: 30px;
  margin: 30px 0 20px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  border-left: 3px solid #ddd;
  font-size: 16px;
  font-weight: 700;
}
.law-content {
  margin: 20px 0;
  font-size: 14px;
}
</style>
