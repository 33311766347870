<template>
  <div>Info 페이지 이동</div>
</template>

<script>
export default {
  setup() {
    window.location.href = "https://b2b.ambience.kr/info";
  },
};
</script>
