interface Works {
  works_no: number;
  works_name_kor: string;
  works_name_eng: string;
  works_type: number;
  works_img_url: string;
  works_site: string;
  works_year: number;
  works_contractor: string;
  works_detail_img: string[];
  works_description: string;
}

export const worksData: Works[] = [
  {
    works_no: 1,
    works_type: 1,
    works_name_kor: "부산 비즈니스 호텔",
    works_name_eng: "Busan Business Hotel",
    works_img_url: require("@/assets/images/works_images/thumbs/img_1.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2016,
    works_detail_img: [
      require("@/assets/images/works_images/details/01_01.jpg"),
      require("@/assets/images/works_images/details/01_02.jpg"),
      require("@/assets/images/works_images/details/01_03.jpg"),
      require("@/assets/images/works_images/details/01_04.jpg"),
      require("@/assets/images/works_images/details/01_05.jpg"),
      require("@/assets/images/works_images/details/01_06.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 2,
    works_type: 2,
    works_name_kor: "만남빌딩",
    works_name_eng: "Multipurpose Building",
    works_img_url: require("@/assets/images/works_images/thumbs/img_2.jpg"),
    works_site: "Changwon",
    works_contractor: "-",
    works_year: 2016,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906095409_v7tlq1i35HQUSflmuaQDxcU8rIAT1r.jpg"),
      require("@/assets/images/works_images/details/20210906095431_LKRkF0q56hm1LsCjq22GvVtMYTkz9J.jpg"),
      require("@/assets/images/works_images/details/20210906095440_PkkhVnl7MX6S7BmOIdvkCvnXp7YIVE.jpg"),
    ],
    works_description: "",
  },
  // {
  //   works_no: 3,
  //   works_type: 1,
  //   works_name_kor: "신세계 백화점 김해점",
  //   works_name_eng: "SHINSEGAE DEPARTMENT STORE Gimhae",
  //   works_img_url: require("@/assets/images/works_images/thumbs/3667564438_ZVEF0ij9_9bda1602be264ffb1a32b07b985b2287dffbf552.jpg"),
  //   works_site: "Gimhae",
  //   works_contractor: "-",
  //   works_year: 2016,
  //   works_detail_img: [
  //     require("@/assets/images/works_images/details/20210906093822_MfdRGW8WsCbAwpDODMAkzHg5aTcW2W.jpg"),
  //     require("@/assets/images/works_images/details/20210906093916_AUy5JHvtETF6FZttdefXh7whNUJMPE.jpg"),
  //     require("@/assets/images/works_images/details/20210906093954_wgxdybcaErja7QnHKAmcbt0rp2JQkA.jpg"),
  //     require("@/assets/images/works_images/details/20210906094005_q9bcFlaA2LpyBHgO0N8gfjwWWLZffM.jpg"),
  //     require("@/assets/images/works_images/details/20210906094015_TebCWYlZB6xEVLItAenm8cOFG0u5fv.jpg"),
  //   ],
  //   works_description: "",
  // },
  {
    works_no: 3,
    works_type: 2,
    works_name_kor: "부산면세점 용두산점",
    works_name_eng: "BUSAN DUTY FREE",
    works_img_url: require("@/assets/images/works_images/thumbs/img_4.jpg"),
    works_site: "Busan",
    works_contractor: "SCinterior",
    works_year: 2018,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906093236_8MDuAhXnMwyiUMBVpUtAl1YGqZc1oP.jpg"),
      require("@/assets/images/works_images/details/20210906093244_HzOP4AxU5AuBOd1rlNJmWHPfgQU3u5.jpg"),
      require("@/assets/images/works_images/details/20210906093251_jXUmT4lSTNkdsgxO4fQUZrJxbaxfsN.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 4,
    works_type: 2,
    works_name_kor: "동국제강 홍보관",
    works_name_eng: "Dongkuksteel PR Center",
    works_img_url: require("@/assets/images/works_images/thumbs/img_5.jpg"),
    works_site: "Busan",
    works_contractor: "Dongkuksteel",
    works_year: 2019,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906094331_b1rAES92NnEfo8Z5YfXf31jEKWA9EP.jpg"),
      require("@/assets/images/works_images/details/20210906094343_QcMsZeKwfElHpvMMyLrraHmFYnLpqW.jpg"),
      require("@/assets/images/works_images/details/20210906094351_r9EUwOkcKybUVVKVWAwGhk4frpBoan.jpg"),
    ],
    works_description: "",
  },
  // {
  //   works_no: 6,
  //   works_type: 1,
  //   works_name_kor: "마리나 블루 키친",
  //   works_name_eng: "MARINA Blue Kitchen",
  //   works_img_url: require("@/assets/images/works_images/thumbs/3667564438_30xJBPQC_9de1b158e2e7065e3385b5b33cc9d48aae5040ad.jpg"),
  //   works_site: "Busan",
  //   works_contractor: "-",
  //   works_year: 2017,
  //   works_detail_img: [
  //     require("@/assets/images/works_images/details/20210906100051_ckLcCaker82RhAuNyvdZ9lH9J1FQmD.jpg"),
  //     require("@/assets/images/works_images/details/20210906100103_PV6AHrHmfPYX28Su9ZOLreUyJSF6X3.jpg"),
  //     require("@/assets/images/works_images/details/20210906100111_zcabG2Bwl5o4QXIhF4nF2L5aeAZwV1.jpg"),
  //   ],
  //   works_description: "",
  // },
  {
    works_no: 5,
    works_type: 1,
    works_name_kor: "시온에스테틱",
    works_name_eng: "SION AESTHETIC",
    works_img_url: require("@/assets/images/works_images/thumbs/img_7.jpg"),
    works_site: "Busan",
    works_contractor: "AESTHETIC",
    works_year: 2017,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906092238_vr7ieM2u9bQ2akuj8YLSqXESaIMSUq.jpg"),
      require("@/assets/images/works_images/details/20210906092250_1Mt9L29t9Hi8pJqLKtMrs9wKlnloFL.jpg"),
      require("@/assets/images/works_images/details/20210906092306_UJDLioWAAqZ0JfSEUuNIBUC888Yjm2.jpg"),
      require("@/assets/images/works_images/details/20210906092327_GVfRXMmkWBHM8FQLjFmGNlxubUmUmn.jpg"),
    ],
    works_description: "",
  },
  // {
  //   works_no: 8,
  //   works_type: 1,
  //   works_name_kor: "상지엔지니어링건축사사무소",
  //   works_name_eng: "Sangji Architects",
  //   works_img_url: require("@/assets/images/works_images/thumbs/3667564438_hrAoBMVE_940ba997cfca1aef63f516f35f08fd70d592247d.JPG"),
  //   works_site: "Busan",
  //   works_contractor: "Sangji Architects",
  //   works_year: 2017,
  //   works_detail_img: [
  //     require("@/assets/images/works_images/details/20210906102124_BfypVJtF8IHrZMUCawOzfpjJChWSzP.jpg"),
  //     require("@/assets/images/works_images/details/20210906102142_z5AMl1V3MAYBuOSPgguzUsokhtOuFe.jpg"),
  //     require("@/assets/images/works_images/details/20210906102153_rjLpVktN03i2CxVXfRJBYXr9iYgarm.jpg"),
  //   ],
  //   works_description: "",
  // },
  // {
  //   works_no: 9,
  //   works_type: 1,
  //   works_name_kor: "헤이나나",
  //   works_name_eng: "Cafe Hey Nana",
  //   works_img_url: require("@/assets/images/works_images/thumbs/3667564438_X6PRa3cV_63a8ce0ba7c9a4e92437736ba52e1fb5eb04874b.jpg"),
  //   works_site: "Changwon",
  //   works_contractor: "Cafe Hey Nana",
  //   works_year: 2018,
  //   works_detail_img: [
  //     require("@/assets/images/works_images/details/20210906095757_wNPqhMcQhokd2djPZ4zgb7hLHXETOx.jpg"),
  //     require("@/assets/images/works_images/details/20210906095810_oPZD8VatPdwDUCAk2A45eWfT1moeeV.jpg"),
  //     require("@/assets/images/works_images/details/20210906095822_czvNsxEdEfRlGs5TIpzcSGN5YYXBIz.jpg"),
  //     require("@/assets/images/works_images/details/20210906095837_1qYvU84x83Z0Uzo5saOCWl5rpLfi7l.jpg"),
  //   ],
  //   works_description: "",
  // },
  {
    works_no: 6,
    works_type: 1,
    works_name_kor: "투썸플레이스 기장일광해변점",
    works_name_eng: "A Twosome Place Ilkwang",
    works_img_url: require("@/assets/images/works_images/thumbs/img_10.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2018,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906100338_fjjFRjKYuwiCVdh6dfISMKLdLl8WW6.jpg"),
      require("@/assets/images/works_images/details/20210906100349_wmmGlQJ3WdfMbauJIQercdMDB9aAou.jpg"),
      require("@/assets/images/works_images/details/20210906100358_cMVqqHQrHHHjXpkzV51BKNN8uFrdqa.jpg"),
      require("@/assets/images/works_images/details/20210906100408_bqXM0dIw2uyVsnx1dEWNMgFtnig6vl.jpg"),
    ],
    works_description: "",
  },
  // {
  //   works_no: 11,
  //   works_type: 1,
  //   works_name_kor: "부산 제 2 항운병원",
  //   works_name_eng: "Busan 2nd Hangun Hospital",
  //   works_img_url: require("@/assets/images/works_images/thumbs/3667564438_lJua76Hi_c3c99adb5ca70b064c6469f22eaa47cb0bbd050c.JPG"),
  //   works_site: "Busan",
  //   works_contractor: "Busan 2nd Hangun Hospital",
  //   works_year: 2018,
  //   works_detail_img: [
  //     require("@/assets/images/works_images/details/20210906101651_1hp6NvFHMOZ8Lb1aHnFYqrVDX9NYNC.jpg"),
  //     require("@/assets/images/works_images/details/20210906101707_7yl8BbnZLsZiwh7zlyrK5mxnPpfuMX.jpg"),
  //     require("@/assets/images/works_images/details/20210906101718_xReGiJcX91lroYbhsZqYf8tNvJszlO.jpg"),
  //     require("@/assets/images/works_images/details/20210906101731_rIzkQ9cvMeibVJEa3L8ajqKa3VM2y1.jpg"),
  //   ],
  //   works_description: "",
  // },
  // {
  //   works_no: 12,
  //   works_type: 1,
  //   works_name_kor: "라파르코",
  //   works_name_eng: "LAPARCO",
  //   works_img_url: require("@/assets/images/works_images/thumbs/3667564438_REz6nHid_58adbab0f6d27d3298af708dcef3c5b53785b0ef.jpg"),
  //   works_site: "Hanam",
  //   works_contractor: "-",
  //   works_year: 2019,
  //   works_detail_img: [
  //     require("@/assets/images/works_images/details/20210906100934_IlpXSQ9CrLKaF8ZDRdWzOvlQ0Gtg5L.jpg"),
  //     require("@/assets/images/works_images/details/20210906100943_PFTZ8wYriqGPHpeEwp3ROeEoeQC2s8.jpg"),
  //     require("@/assets/images/works_images/details/20210906100954_3UBlZAeVUCnsUTGIRBTemfr5Vg5bLp.jpg"),
  //     require("@/assets/images/works_images/details/20210906101004_Z6UXwVrR6F0pFhJprfJptQWH3rNUzf.jpg"),
  //   ],
  //   works_description: "",
  // },
  {
    works_no: 7,
    works_type: 1,
    works_name_kor: "에테르",
    works_name_eng: "Aether Cafe",
    works_img_url: require("@/assets/images/works_images/thumbs/img_13.jpg"),
    works_site: "Busan",
    works_contractor: "Aether Cafe",
    works_year: 2019,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906103535_QJRS4QixVh3CEn3aAPVawFlW3LWbOq.jpg"),
      require("@/assets/images/works_images/details/20210906103552_35D5vm8lWmpOfeIWJXZ2UltfBioQvc.jpg"),
      require("@/assets/images/works_images/details/20210906103602_inTWqI3gf5Ij1G3VfvOF7jL9dnrEku.jpg"),
      require("@/assets/images/works_images/details/20210906103611_E9208zkzhS5TnCoi6yNZ7Q1o3oRzYf.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 8,
    works_type: 1,
    works_name_kor: "해운대 비치 골프앤리조트",
    works_name_eng: "Haeundae Beach Golf&Resort",
    works_img_url: require("@/assets/images/works_images/thumbs/img_14.png"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2019,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906101437_ywB9mh44D4ftvxJggjPWXUPP6ygw2g.jpg"),
      require("@/assets/images/works_images/details/20210906101446_T6RSr6fvfnUt247Bjt7yERNTIhdk9D.jpg"),
      require("@/assets/images/works_images/details/20210906101456_ozxnhMVxQie7WPmoNgfigm3B5JMbnu.jpg"),
      require("@/assets/images/works_images/details/20210906101506_25VpYf8UgPInIsjgtTB1zTMnrYLBcz.png"),
    ],
    works_description: "",
  },
  {
    works_no: 9,
    works_type: 1,
    works_name_kor: "부경대학교 이오카페",
    works_name_eng: "PKNU EO Cafe",
    works_img_url: require("@/assets/images/works_images/thumbs/img_15.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906103900_lF3iwgkLXfPqiinwtnkOZvJm9HFs0L.jpg"),
      require("@/assets/images/works_images/details/20210906103914_SmAc3GKnJ4aIbUbeZirN5zLOdOrThM.jpg"),
      require("@/assets/images/works_images/details/20210906103923_SHYLktpj64Kc9lnNZ58l3J3dfxeFyg.jpg"),
      require("@/assets/images/works_images/details/20210906103935_W77Um66Mb9jE8RrI1AqDIGd1uw8HUX.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 10,
    works_type: 1,
    works_name_kor: "부경대학교 교양관",
    works_name_eng: "Pukyong National University",
    works_img_url: require("@/assets/images/works_images/thumbs/img_16.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906103736_8Dlyqw85C3xDKZv6zYAhZjK2M7jUUb.jpg"),
      require("@/assets/images/works_images/details/20210906103745_Yn7ByO33z8j116qmKNaIUnfKRyhqR5.jpg"),
      require("@/assets/images/works_images/details/20210906103752_KdqrTGkSIhIi3vK6HzIL0jagiPCmUK.jpg"),
      require("@/assets/images/works_images/details/20210906103759_pqLC1tfLLJHw7O99oqLzDow9SMxcqT.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 11,
    works_type: 1,
    works_name_kor: "에코마인 & 비비솔루션 사옥",
    works_name_eng: "ECOMINE & BB SOLUTIN Building",
    works_img_url: require("@/assets/images/works_images/thumbs/img_17.jpg"),
    works_site: "Busan",
    works_contractor: "ECOMINE & BB SOLUTIN",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906104333_ySyAmoSpDkVY9iuXdDa4quLiBo25Wx.jpg"),
      require("@/assets/images/works_images/details/20210906104342_ic8pZ552ZMphZYRMy62yL8acBsFVZW.jpg"),
      require("@/assets/images/works_images/details/20210906104353_MycARquikmzB7QfuTlwgPC9cZOM5da.jpg"),
      require("@/assets/images/works_images/details/20210906104407_PG3u2roXi5dghalF2SE6h3frl3CEKF.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 12,
    works_type: 1,
    works_name_kor: "한국해양대학교 도서관",
    works_name_eng: "Korea Maritime and Ocean University Library",
    works_img_url: require("@/assets/images/works_images/thumbs/img_18.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906105058_vTkKjTr9hAOaeqBjbMedjsxm7w2vYo.jpg"),
      require("@/assets/images/works_images/details/20210906105106_0Q51MUZHv9c8bNSGUxRnA0hvpp5Xqd.jpg"),
      require("@/assets/images/works_images/details/20210906105116_AKUZ9wDdkjsoZomOnLhyQIHh4dPRoi.jpg"),
      require("@/assets/images/works_images/details/20210906105133_0cx6Qm23adtLSw6aiLigrxQ6uefR6R.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 13,
    works_type: 1,
    works_name_kor: "춘심이네",
    works_name_eng: "Chunsim restaurant",
    works_img_url: require("@/assets/images/works_images/thumbs/img_19.jpg"),
    works_site: "Jeju",
    works_contractor: "-",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906110316_4SVdQoKhlfxLE7W74lE3fUPp5GcxxQ.jpg"),
      require("@/assets/images/works_images/details/20210906110336_LxxJRK7mxKNeo7O3MLF7HKLNoduKZE.jpg"),
      require("@/assets/images/works_images/details/20210906110417_3FK8mgROr2deynJ07AB3NEHQtfblcn.jpg"),
      require("@/assets/images/works_images/details/20210906110431_M3vbLGfDgW9gk0yHdfcQtXIPtA6hXp.jpg"),
      require("@/assets/images/works_images/details/20210906110452_z0ecFiNXeCLak07wzgD9lxbAQRhGc2.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 14,
    works_type: 2,
    works_name_kor: "성원 메디컬빌딩",
    works_name_eng: "Sungwon Medical Building",
    works_img_url: require("@/assets/images/works_images/thumbs/img_20.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906110721_IFc9Z90OQM8PX2ZLpe8ssMatXo24ZG.jpg"),
      require("@/assets/images/works_images/details/20210906110737_rcNIYk1qyCC4jJI1CARnhf55eSquhh.jpg"),
      require("@/assets/images/works_images/details/20210906110756_ixdwa4mOMKaIMbGOSdoanKGt8WnfwF.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 15,
    works_type: 2,
    works_name_kor: "부산 엑스 더 스카이",
    works_name_eng: "Busan X the SKY",
    works_img_url: require("@/assets/images/works_images/thumbs/img_21.jpg"),
    works_site: "Busan",
    works_contractor: "DAWON & Company",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906111000_GVGocZvNmDowXyMkw5cmspQkJ97jgZ.jpg"),
      require("@/assets/images/works_images/details/20210906111009_1JQJtet52VNaRbgORB32NfDW0G0Ywf.jpg"),
      require("@/assets/images/works_images/details/20210906111018_trCWzeny8p4Z0Lq8wuRP5C0gzjIatA.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 16,
    works_type: 1,
    works_name_kor: "부산 엑스 더 스카이",
    works_name_eng: "Busan X the SKY",
    works_img_url: require("@/assets/images/works_images/thumbs/img_22.jpg"),
    works_site: "Busan",
    works_contractor: "DAWON & Company",
    works_year: 2020,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906104112_UZ1CGsWTmk4x8ydnplYyayghkZuA7y.jpg"),
      require("@/assets/images/works_images/details/20210906104121_wTAeRjiUlPajYuPNN4ILLOJ8O4WWUV.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 17,
    works_type: 1,
    works_name_kor: "한국해양대학교 아치 라운지",
    works_name_eng: "Korea Maritime and Ocean University ACHI Lounge",
    works_img_url: require("@/assets/images/works_images/thumbs/img_23.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2021,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906110112_zICslqg0QepR29hxMZFSaqVuPGmMVj.jpg"),
      require("@/assets/images/works_images/details/20210906110119_AiMtQmRmXykZANRfZgeD6qabPnKFUG.jpg"),
      require("@/assets/images/works_images/details/20210906110127_IYwlbUFeUK8cAxfp0hifCOFkgfUjvv.jpg"),
      require("@/assets/images/works_images/details/20210906110136_IfuAqxCAmrHREFxiggz5yFsJDoVEhU.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 18,
    works_type: 1,
    works_name_kor: "신라스테이 서부산",
    works_name_eng: "SHILLA STAY Seobusan",
    works_img_url: require("@/assets/images/works_images/thumbs/img_24.jpg"),
    works_site: "Busan",
    works_contractor: "EUNMIN",
    works_year: 2021,
    works_detail_img: [
      require("@/assets/images/works_images/details/20210906111223_k1qxDKPx3kjLSmLWDxkOCTZPVUiP9H.jpg"),
      require("@/assets/images/works_images/details/20210906111233_iGmRuDxWPci5vwrPvdGDYzCuXnwvXW.jpg"),
      require("@/assets/images/works_images/details/20210906111243_fWp884yuT1x5g90ciVkDd1HVDOjTml.jpg"),
      require("@/assets/images/works_images/details/20210906111254_ZqULlHeecwSUY3j7Btc3pPa4HQS8av.jpg"),
      require("@/assets/images/works_images/details/20210906111306_f1p41MoWHulvbUhRQaYC2LP0cCMS3D.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 19,
    works_type: 1,
    works_name_kor: "스타자동차 메르세데스-벤츠 인증중고차 전시장",
    works_name_eng: "Star Motor Mercedes-Benz Certified",
    works_img_url: require("@/assets/images/works_images/thumbs/img_25.jpg"),
    works_site: "Busan",
    works_contractor: "H DESIGN GROUP",
    works_year: 2021,
    works_detail_img: [
      require("@/assets/images/works_images/details/20211102171210_AMLvucZql9o8VSR0Ewwn8rRfQq4CMn.jpg"),
      require("@/assets/images/works_images/details/20211102171241_ifZpmLDYrlLsBAQah0877ofuS94IUR.jpg"),
      require("@/assets/images/works_images/details/20211102171258_fLNoztBdDHw6BS8vPGvQ3dPoTGAcEI.jpg"),
      require("@/assets/images/works_images/details/20211102171313_9XZdApQUnELfc6B043v8oaR0G220Tm.jpg"),
      require("@/assets/images/works_images/details/20211102171347_GEoM8VqPNuuavv9hCE7RptVAUWIpaZ.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 20,
    works_type: 2,
    works_name_kor: "부산 지동범 동물메디컬 센터",
    works_name_eng: "Dr . JI Animal medical center",
    works_img_url: require("@/assets/images/works_images/thumbs/img_26.jpg"),
    works_site: "Busan",
    works_contractor: "Kanghan Construction Inc.",
    works_year: 2021,
    works_detail_img: [
      require("@/assets/images/works_images/details/20220412235658_jpMmzvxNqKQHp68bXzt5Yh5gf4AqCs.jpg"),
      require("@/assets/images/works_images/details/20220412235731_pm2BmJo8zNB1F5SXnQfjUAkwl6dPaa.jpg"),
      require("@/assets/images/works_images/details/20220412235829_YxO6jcztQjYo77sDF0Jm1KyaFGJVX6.jpg"),
    ],
    works_description: "",
  },
  {
    works_no: 21,
    works_type: 1,
    works_name_kor: "국회부산도서관",
    works_name_eng: "National Assembly Busan Library",
    works_img_url: require("@/assets/images/works_images/thumbs/img_27.jpg"),
    works_site: "Busan",
    works_contractor: "-",
    works_year: 2022,
    works_detail_img: [
      require("@/assets/images/works_images/details/20220519041147_kWQdITaBBnI3wP1uGk2x9SLsBBVVFp.jpg"),
      require("@/assets/images/works_images/details/20220519041203_N2Hx5sQgLStSyp39xjJ42x5S4FRDgQ.jpg"),
      require("@/assets/images/works_images/details/20220519041219_ysonPpfoz4Hc53l2Acif6aF2KUUHwd.jpg"),
      require("@/assets/images/works_images/details/20220519041235_ad10KXOth8vXEDkmQYlwmDeutItGWm.jpg"),
      require("@/assets/images/works_images/details/20220519041340_OcTx3jTaBpqgDpgEIxRPGwyJb3lL2y.jpg"),
      require("@/assets/images/works_images/details/20220519041401_8BLioxMKhZItcM0HXcSPBtuRxLoALu.jpg"),
      require("@/assets/images/works_images/details/20220519041427_oZTVXuHWx2Ef1GX1QBH1KUQNDAi2Fq.jpg"),
      require("@/assets/images/works_images/details/20220519041456_8so4TN6vP4tfGvD0hJem9ScFJ0D7cv.jpg"),
      require("@/assets/images/works_images/details/20220519041543_Ojivhfc9kJNWesfIJyyWbpQ0inulg7.jpg"),
      require("@/assets/images/works_images/details/20220519041555_tDwHjPmsNQTCGDiiijbsrIroxwoZng.jpg"),
      require("@/assets/images/works_images/details/20220519041604_mcvXXHFknh3C9KtRE6PH6HzLyvY31u.jpg"),
      require("@/assets/images/works_images/details/20220519041614_Edk3g1tFXzIoKiH6BYT9qaNL6War8B.jpg"),
      require("@/assets/images/works_images/details/20220519041626_fTGsnTwQZUBSSXZeAKrFgTBYsMUxBf.jpg"),
      require("@/assets/images/works_images/details/20220527035541_YOQmsqEQIUtWrB7Vv0VbBUJyzhWWTM.jpg"),
    ],
    works_description: `
2022년 3월 개관한 국회부산도서관의 실내조명 설계, 납품, 시공을 완료했습니다.

지역민을 위해 열려 있는 복합 문화공간으로 거듭날 수 있도록 편안함을 느낄 수 있는 빛, 고급스러운 인테리어 두 마리 토끼를 모두 잡을 수 있도록 심혈을 기울였습니다.

저희 앰비언스에서 국내 독점으로 전개하고 있는 이태리 하이엔드 조명 브랜드 루체플랜의 Acoustic 라인업은 공간에서 난반사되는 소음을 흡수하는 기능을 가진 인테리어 조명입니다.

이번 프로젝트에는 루체플랜의 페탈레 모델이 1층 종합 자료실에 7대, 2층 주제 자료실에 1대 채용되어 총 8대의 Acoustic 라인업 제품을 시공하였습니다.

이와 더불어 덴마크의 베르판, 루이스폴센, 구비 등 인지도와 미적 가치가 높은 브랜드의 조명을 적재적소에 배치하여 국회도서관에 걸맞는 품격을 완성하였습니다.`,
  },
];
