<template>
  <div class="doc-header-container">
    <span
      @click="goDocPage(1)"
      class="doc-header-btn"
      :class="state.tab_number === 'policy' && 'active'"
    >
      이용약관
    </span>
    <span>|</span>
    <span
      @click="goDocPage(2)"
      class="doc-header-btn"
      :class="state.tab_number === 'private' && 'active'"
    >
      개인정보처리방침
    </span>
    <span>|</span>
    <span
      @click="goDocPage(3)"
      class="doc-header-btn"
      :class="state.tab_number === 'antiemail' && 'active'"
    >
      이메일무단수집거부
    </span>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      tab_number: route.name,
    });
    const goDocPage = (cate_number) => {
      switch (cate_number) {
        case 1:
          router.push({ name: "policy" });
          break;
        case 2:
          router.push({ name: "private" });
          break;
        case 3:
          router.push({ name: "antiemail" });
          break;
        default:
          router.push({ name: "policy" });
      }
    };
    return {
      state,
      goDocPage,
    };
  },
};
</script>

<style scoped>
.doc-header-container {
  display: flex;
  justify-content: center;
}
.doc-header-container span {
  padding: 20px;
  line-height: 20px;
  font-size: 18px;
  font-weight: 500;
}
.doc-header-btn {
  transition: all 0.2s;
  cursor: pointer;
}
.doc-header-btn:hover {
  color: #ef9600;
}
.active {
  color: #ef9600;
  text-decoration: underline;
  font-weight: 700;
}
@media (max-width: 600px) {
  .doc-header-container span {
    padding: 5px;
    font-size: 16px;
  }
}
</style>
