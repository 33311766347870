<template>
  <tr>
    <th rowspan="2">* 현장 주소</th>
    <td colspan="3" style="padding-bottom: 0">
      <div class="main-address-box">
        <input
          type="text"
          placeholder="예시 : 도로명, 건물명, 지번을 검색해주세요."
          v-model="addr1"
          class="main-input"
          readonly
          required
        />
        <input
          type="text"
          placeholder="우편번호"
          v-model="zip"
          class="zip-input"
          readonly
          required
        />
        <button @click="showApi">주소 찾기</button>
      </div>
    </td>
  </tr>
  <!-- <div ref="embed"></div> -->
  <tr class="sub-address-box">
    <td colspan="3">
      <div class="mobile-option">
        <input
          type="text"
          placeholder="기타 주소 입력"
          v-model="addr2"
          @input="updateAddress"
          class="sub-input"
          required
          maxlength="50"
        />
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "daumMap",
  emits: ["handleChangeAddress", "changeDetailAddress"],
  data() {
    return {
      zip: "",
      addr1: "",
      addr2: "",
    };
  },
  methods: {
    showApi() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
          let extraRoadAddr = ""; // 도로명 조합형 주소 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== "" && data.apartment === "Y") {
            extraRoadAddr +=
              extraRoadAddr !== ""
                ? ", " + data.buildingName
                : data.buildingName;
          }
          // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== "") {
            extraRoadAddr = " (" + extraRoadAddr + ")";
          }
          // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
          if (fullRoadAddr !== "") {
            fullRoadAddr += extraRoadAddr;
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.zip = data.zonecode; //5자리 새우편번호 사용
          this.addr1 = fullRoadAddr;
          this.$emit("handleChangeAddress", data.zonecode, fullRoadAddr);
        },
      }).open();
    },
    updateAddress() {
      this.$emit("changeDetailAddress", this.addr2);
    },
  },
};
</script>

<style scoped>
tr {
  display: table-row;
  width: 100%;
}
th,
td {
  padding: 15px 30px;
}
th {
  width: 185px;
  background: #f7f7f7;
  font-size: 16px;
  color: #111;
  font-weight: 300;
}
input {
  height: 40px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding-left: 10px;
  outline: none;
}
.main-address-box {
  display: flex;
  align-items: center;
}
.sub-address-box {
  border-bottom: 1px solid #ddd;
}
.main-input {
  width: 100%;
  cursor: default;
}
.zip-input {
  width: 64px;
  padding: 0;
  margin: 0 10px;
  text-align: center;
  cursor: default;
}
.sub-input {
  width: 100%;
}
button {
  margin: 0;
  padding: 0;
  width: 105px;
  height: 40px;
  line-height: 40px;
  background-color: #555;
  color: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
}
@media (max-width: 600px) {
  th {
    display: none;
  }
  td {
    margin: 0;
    padding: 0;
  }
  .mobile-option {
    margin-top: 10px;
  }
}
</style>
