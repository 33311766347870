interface News {
  news_no: number;
  news_title: string;
  news_summary: string;
  news_content: string;
  news_date: string;
  news_thumbs_url: string;
  news_references_img: string[];
  news_references_description: string[];
  news_link: string;
}

export const newsData: News[] = [
  {
    news_no: 1,
    news_title:
      "[스페셜경제] 앰비언스, '해운대비치 골프 앤 리조트 조명 프로젝트' 수주",
    news_summary:
      "초호화 리조트를 단순히 설계한 것을 넘어 설계와 납품, 시공에 이르기까지 조명에 관련된 전반적인 토탈 서비스를 도와 리조트의 완성도를 높였습니다.",
    news_content: `
    [스페셜경제 = 박대성 기자] 국내 소득 수준이 높아지고 현대인들의 워라밸이 중요시되면서 여가와 레저 등의 활동에 관심을 가지는 사람들은 꾸준히 증가하는 추세다. 레저와 골프에 대한 관심이 높아지면서 자연스레 많은 관심을 받는 곳이 바로 ‘리조트’다.
    
    리조트는 레저와 휴식을 어우르는 공간인 만큼 조명의 중요성이 높다. 현대적 감각이 살아있는 럭셔리함과 모던한 인테리어, 그리고 넓고 쾌적한 휴식을 즐길 수 있는 해운대비치 골프 앤 리조트의 조명 설계와 컨설팅 작업은 조명 설계 전문 업체 앰비언스가 도맡아 진행했다.
    
    앰비언스는 초호화 리조트를 단순히 설계한 것을 넘어 설계와 납품, 시공에 이르기까지 조명에 관련된 전반적인 토탈 서비스를 도와 리조트의 완성도를 높였다.
    
    앰비언스(AMBIENCE)는 2012년에 창립한 조명 3D 시뮬레이션 및 설계 컨설팅 전문 업체로 전반적인 조명 토탈 솔루션을 제안한다. 또한 일련의 특화된 과정을 통하여 초기 조명 기획 설계 단계부터 제품의 납품, 감리 및 시공에 이르기까지 전문화된 팀이 클라이언트에게 최상의 만족도를 안겨주고 있다.`,
    news_date: "021/09/07 12:35",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_mwbEGROZ_c65742d2ec655d2eaa2db132d1ddcdde80dd3842.jpg"),
    news_references_img: [
      require("@/assets/images/news_images/references/20210908104026_JudP6hWxupLt9YCA7BVpqmrS2wsfh9.jpg"),
    ],
    news_references_description: ["해운대비치 골프 앤 리조트 완공"],
    news_link: "",
  },
  {
    news_no: 2,
    news_title:
      "[파이낸스투데이] 최근 떠오르는 건축조명 설계 컨설팅 전문회사 '앰비언스'",
    news_summary:
      "앰비언스는 포항 테라원 조명의 기획설계부터 납품/시공/감리까지 조명에 관련된 모든 업무를 맡았습니다.",
    news_content: `
    건축/건설시장에서 조명설계 및 디자인은 건설의 그저 하부영역으로 여겨져 왔고, 이는 건설시장에서의 조명설계 및 품질경쟁력 악화를 불러왔다. 앰비언스(AMBIENCE)는 2012년에 창립한 조명 3D시뮬레이션 및 설계 컨설팅 전문회사로서, 건축/건설시장에서 조명의 기획단계부터 실시설계, 납품, 시공/감리에 이르기까지 조명에 관련된 토털솔루션을 건축/건설시장에 제안하고 있다.
    
    앰비언스의 건축조명설계는 3D 조명 시뮬레이션 프로그램을 통해 디테일한 표현이 가능하고, 건물 내/외부에 섬세한 빛의 분포표현, 빛의 강도(Lumen) 및 컬러(CK)선택 빛의 퍼짐의 정도 및 각도조절 상대적으로 짧은 제작기간과 저렴한 설계가격, 조경조명(나무/분수/가로길) 및 경관조명(건물외관/다리/공원)에 특화 각종 인/허가자료로 유용한 여러 가지 장점이 있어 조명으로 건물이 가진 본질적 가치를 더욱 빛날 수 있는 서비스를 제공한다.

    앰비언스의 전문분야는 건물(빌딩) 내외부의 경관조명, 호텔/리조트 조명, 병원조명, 카페조명, 산업조명, 주거조명, 매장조명이다. 최근 포항 테라원의 조명의 기획설계부터 납품/시공/감리까지 조명에 관련된 모든 업무를 맡아 업계의 주목을 받고 있다. 전 세대 테라스설치 타운하우스인 테라원은 29세대, 1,245평 규모의 타운하우스로 ‘산과 녹지, 그리고 눈앞에 펼쳐지는 형산강’이라는 자연친화적 컨셉으로 입지적 프리미엄을 제공하고 있다.

    앰비언스 김대웅 대표는 “국내 건축/건설 시장에서 선진국에 비해 낙후된 조명서비스 및 저품질의 고질적인 문제를 해결하는 것이 앞으로 앰비언스가 나가야 할 장기적인 방향이며, 향후 건설/건축 시장에 합리적인 가격에 높은 수준의 조명컨설팅서비스를 제공하는 것이 목표”라며 건설/건축시장에서 건축조명기업으로써의 역할론을 역설했다.이어 김 대표는 “향후 건축조명 비즈니스의 가능성은 무궁무진하다” 라며 건축조명설계의 무한한 가능성을 강조했다. 건축 조명 설계 문의 및 자세한 사항은 앰비언스 홈페이지 및 대표번호 전화 상담을 통해 확인할 수 있다.`,
    news_date: "2021/09/07 12:39",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_KBt3fLqv_4263e6c567925ab30767c884630ccf572936a69f.jpg"),
    news_references_img: [
      require("@/assets/images/news_images/references/20210908104336_iiJc6eqhiinBt9wSxczeYZMDAJ1ewx.jpg"),
    ],
    news_references_description: ["최초 포항 테라원 3D 조감도"],
    news_link: "http://www.fntoday.co.kr/news/articleView.html?idxno=201479",
  },
  {
    news_no: 3,
    news_title:
      '[아이티비즈] 조명 설계 전문 앰비언스 "공간에 최적화된 조명 서비스로 만족도 상승"',
    news_summary:
      "일광 투썸플레이스의 조명설계와 컨설팅 작업은 조명 디자인 전문 회사 앰비언스가 진행했습니다.",
    news_content: `
    선선해진 가을에는 나들이객이 크게 늘어난다. 특히 시원한 바다를 만날 수 있는 부산은 국내, 외 관광객들에게 사랑받는 대표적인 도시다. 그 중에서도 ‘기장’은 아름다운 오션뷰를 여유롭게 감상할 수 있는 명소로 주목받고 있다. 최근에는 이러한 특색을 활용한 카페들이 대거 등장해 ‘핫플레이스’로 인기를 얻는 중이다.
    
    이러한 추세 속에서 부산 기장 해안도로를 중심으로 수많은 커피전문점과 디저트 카페가 포진해 경쟁이 치열해지고 있다. 때문에 저마다 차별화 포인트를 내세워 관광객 사로잡기에 열을 올리는 중이다. 기장 일광 투썸플레이스는 2018년 부산시 건축 금상을 수상한 카페로, 백사장 위에 지어졌다는 입지적 특성으로 눈길을 끌고 있다.
    
    조화로운 조명 배치로 아늑한 분위기가 돋보인다는 평도 얻고 있다. 일광 투썸플레이스의 조명설계와 컨설팅 작업은 조명 서비스 전문 회사 앰비언스가 맡아 진행했다. 앰비언스는 2012년 창립된 조명3D 시뮬레이션 및 설계 컨설팅 전문 회사로, 조명 서비스에서 전문성을 보유하고 있다. 초기 조명 기획설계부터 제품의 납품, 그리고 감리 및 시공에 이르기까지 전문화된 팀이 함께한다. 카페, 호텔, 병원, 매장, 사무실, 주거, 조경, 경관 등 다양한 공간에 최적화된 조명설계 작업을 실시하고 있다.
    
    이와 관련해 앰비언스 관계자는 “일광 투썸플레이스는 기획∙설계 단계부터 클라이언트와 적극적인 소통으로 디자인 콘셉트를 정했다. 도로를 따라 보이는 건물 외부의 간접조명, 외부 볼라드 조명, 실내의 펜던트 조명 등 세 가지를 주요 포인트로 삼았다”고 설명했다.
    
    건물의 모든 내, 외부 조도를 파악해 한국 조도기준표를 기준으로 설계를 진행했고, 다수 조명 제품을 꼼꼼히 비교해 선택했다는 것이 관계자의 설명이다. 어두워 보일 수 있는 건물 외벽에는 지중등을 설치해 볼라드 등과 조화로운 분위기를 연출하고, 조도 확보가 어려운 공간에는 레일 직부등을, 계단에는 스텝 조명을 설치해 조도를 확보했다.
    
    앰비언스 관계자는 “건축물의 아름다움을 돋보이게 하는 방법은 여러 가지가 있지만 조명은 이 모든 것을 조화롭게 연출하는 매력이 있다. 조명의 배치, 각도, 종류에 따라 우아한 분위기가 연출되기도 하며, 때로는 포인트 연출도 가능하다. 일광 투썸플레이스의 경우 방문 고객에게 편안함을 제공하면서 다시 오고 싶은 카페로 느낄 수 있도록 하는데 초점을 맞췄다”고 덧붙였다.`,
    news_date: "2021/09/07 12:42",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_tXx5EGFz_c2032c65c2a814c3826f48e39fd03e6a6e22d9ba.jpg"),
    news_references_img: [
      require("@/assets/images/news_images/references/34265_32047_2413.jpg"),
    ],
    news_references_description: [" 부산시 일광에 위치한 투썸플레이스"],
    news_link: "http://www.it-b.co.kr/news/articleView.html?idxno=34265",
  },
  {
    news_no: 4,
    news_title:
      "[뉴스브라이트] 조명 설계 전문업체 앰비언스, '부산 제2 항운병원' 병원 조명설계 선보였다.",
    news_summary:
      "병원 조명설계는 각기 공간의 용도가 달라 조도 및 기구 설치 위치 등 다양한 요구조건을 충족하면서도 공간적 특징을 잘 살려야 하므로 조명 설계 지식과 경험이 중요합니다.",
    news_content: `
    몸이 아프거나 건강에 이상이 생기면 바로 가야 할 곳은 병원이다. 병원은 치료와 휴식을 위해 찾는 공간으로 심신의 안정을 위한 조명 설계가 중요하게 여겨지는 장소이다. 특히 병원은 환자, 보호자, 의료진 등 각기 다른 요구에 맞는 조명이 필요해 조명 설계가 까다롭다. 
    
    병원조명은 환자의 정서를 건강하게 하고 충분한 휴식을 취할 수 있는 공간을 제공해야하는 역활을 수행해야 한다. 또한 다양한 기능과 사람이 모이는 장소인 만큼 조명은 그 용도에 맞는 역할을 다하면서도 최우선이 돼야할 환자를 중심으로 설계돼야 한다.
    
    병원 조명설계는 각기 공간의 용도가 달라 조도 및 기구 설치 위치 등 다양한 요구조건을 충족하면서도 공간적 특징을 잘 살려야 하므로 조명 설계 지식과 다년간의 경험이 중요하다. 이러한 측면에서 각 공간에 맞는 주요 사항들을 조합하고 고려해 모두가 만족할 수 있는 완성도 높은 조명 설계를 도맡아 진행하고 있는 조명 설계 전문 업체 '앰비언스'가 많은 관심을 집중시키고 있다.
    
    앰비언스(AMBIENCE)는 2012년 창립한 조명 3D 시뮬레이션 및 설계 컨설팅을 전문으로 진행하는 업체로 전반적인 조명 토탈 솔루션을 제안하고 있다. 특히 조명의 설계뿐만 아니라 납품, 시공까지 조명과 관련된 전반적인 과정 대부분을 꼼꼼하게 진행한다는게 업계의 전반적인 평가다.
    
    앰비언스 관계자는 "병원의 설계에 있어 특히 병실은 환자에게 스트레스를 주지 않으면서 심리적인 안정감을 주기 위해서 간접조명을 사용했고 그림들도 함께 전시돼 있어 환자들이  심리적으로 편안함을 느끼는데 중점을 뒀다"며 "환자가 이용하는 장소인 만큼 지나치게 눈부시지 않는 적절한 조도를 확보하는 조명설계가 중요하다"고 전했다.
    
    한편 앰비언스 관련 자세한 정보와 포트폴리오, 상담 문의는 홈페이지 및 대표번호를 통해 하면 된다.`,
    news_date: "2021/09/07 12:46",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_aXzhd1qc_6e4d5d2ad34a9688d3d060aa55032db84faa8d70.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "http://www.newsbrite.net/news/articleView.html?idxno=131727",
  },
  {
    news_no: 5,
    news_title:
      "[아시아 타임즈] 앰비언스, 부산 면세점 용두산점 조명 토탈 솔루션 제공",
    news_summary:
      "적절한 조명 기구를 적용하고, 올바른 배광을 활용하여 다양한 요구 조건을 충족해야하며, 앰비언스는 용두산점 면세점 솔루션을 제공하였습니다.",
    news_content: `
    용두산점 면세점은 부산 원도심 관광 중심지 용두산공원 지역의 관광, 문화와 더불어 쇼핑의 기쁨도 누릴 수 있는 시내면세점이다. 시내면세점의 특징은 공항 면세점과 달리 방문 접근성이 수월하며 수많은 이용객들이 연중 구분 없이 방문한다는 것이다.

    면세점 특성상 국내외 다양한 이용객들이 방문하기 때문에 과하지 않은 조명들로 고객들이 편안함을 느끼면서 쇼핑에 집중할 수 있는 것에 먼저 초점을 맞추어야 한다. 이처럼 면세점 조명 설계는 각 브랜드의 품목 및 컨셉에 맞춰 조도, 광원, 경관 등 다양한 요구 조건을 충족하면서도 공간적 특징을 잘 살려야 한다. 이에 용두산점 면세점 솔루션을 제공한 조명 설계 전문 업체 ‘앰비언스’가 많은 이목을 집중시키고 있다.

    앰비언스(AMBIENCE)는 2012년 창립한 조명 3D 시뮬레이션 및 설계 컨설팅을 전문으로 진행하는 B TO B 전문업체로 전반적인 조명 토탈 솔루션을 제안하고 있다. 앰비언스 관계자는 “면세점 조명은 상품이 돋보이면서도 소비자들이 편안하게 매장에 머무르면서 쇼핑할 수 있게끔 눈부심이 덜한 조명 제품들을 활용해야 한다”며 "구매 욕구를 일으키는 상품 진열이나 진입 동선도 중요하지만 각 브랜드의 이미지에 맞는 분위기를 만드는 것은 조명의 역할이기 때문에 보다 다양하고 신중하게 조명 제품을 고려해야 한다”고 전했다.`,
    news_date: "2021/09/07 12:56",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_rvW7Tcj4_868f2d05fbd34afa980a0460e41c2d3b7bc78d28.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "https://www.asiatime.co.kr/1065575979902425?1=1",
  },
  {
    news_no: 6,
    news_title:
      "[아시아 타임즈] 앰비언스, 이탈리아 조명브랜드 루체플랜의 실렌지오 국내 적용",
    news_summary:
      "실렌지오는 고융의 소음 흡수 기술로 소음을 최대 20~50%까지 흡수해주며, 패브릭 소재의 따뜻한 조명입니다.",
    news_content: `
    앰비언스(AMBIENCE)는 2012년에 창립한 조명 3D 시뮬레이션 및 설계 컨설팅 전문 기업으로, 건축/건설시장에서 조명의 기획단계부터 실시설계, 납품, 시공/감리에 이르기까지 조명에 관련된 토탈 솔루션을 제안한다. 최근 앰비언스는 부산시 국립 부경대학교 교양관 조명설계에서 이탈리아의 조명브랜드 루체플랜(LUCEPLAN)의 실렌지오(SILENZIO)를 국내 최초 적용했다. 실렌지오는 조명임에도 불구하고, 고유의 소음 흡수 기술로 소음을 최대 20%~50%까지 흡수해주며, 패브릭 소재와 따뜻한 조명으로 알려졌다.
      
    앰비언스의 관계자는 “국립대학교인 부경대학교의 교양관은 본래 교양수업을 위한 공간이었으나 현재는 강의뿐만 아니라 동아리 활동의 용도를 더한 공간으로 변화했다. 이에 학생들이 자유롭게 협업하고 공부를 할 수 있는 환경 속에서 기분과 정서, 건강에 이르기까지 긍정적인 영향을 미칠 수 있는 루체플랜(LUCEPLAN)의 실렌지오가 적합했다”고 말했다. 이어 “일상, 학업, 업무 중 발생하는 소음 공해는 정서적, 심리적 영향을 미친다. 실렌지오의 소음 흡수 기술을 통해 공해에 대한 불편함을 덜어낼 수 있다. 이처럼 브랜드 이미지에 맞는 분위기를 연출하고 공간에 맞는 조명의 선택에는 늘 신중을 기여해야 한다”고 덧붙였다.

    앰비언스의 건축조명설계는 3D 조명 시뮬레이션 프로그램을 통해 디테일한 표현이 가능하고, 건물 내/외부에 섬세한 빛의 분포표현, 빛의 강도(Lumen) 및 컬러(CK) 선택, 빛의 퍼짐의 정도 및 각도조절, 상대적으로 짧은 제작 기간과 저렴한 설계가격, 조경조명(나무/분수/가로길) 및 경관조명(건물 외관/다리/공원)에 특화, 각종 인/허가자료로 유용한 여러 가지 장점이 있어 조명으로 건물이 가진 본질적 가치를 더욱 빛날 수 있는 서비스를 제공한다.`,
    news_date: "2021/09/07 13:00",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_o8OYwr1u_84964af6015a014d9466b935c72b771b11a38a74.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "https://www.asiatime.co.kr/1065576846679290?1=1",
  },
  {
    news_no: 7,
    news_title: "라이팅뉴스 2020년 1월호",
    news_summary:
      "앰비언스, 차별화된 조명설계 평가 / 부산지역 대표기업으로 자리매김 전망",
    news_content: ``,
    news_date: "2021/09/07 12:58",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_xhdDMYZK_8846a8e60daf7e404ecb6be919ecc24522ca3b61.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "",
  },
  {
    news_no: 8,
    news_title: "라이팅뉴스 2020년 4월호",
    news_summary:
      "앰비언스, 루체플랜 '실렌지오' 첫 적용. 부경대 교양관 조명설계에 적용돼 호평",
    news_content: ``,
    news_date: "2021/09/07 13:01",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_jvoLFzsU_c2b20e3064f72390c69f25eb2552464e6a5f722f.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "",
  },
  {
    news_no: 9,
    news_title:
      "[아시아 타임즈] 앰비언스, 제주 엠버 리조트 프로젝트 조명 설계 · 컨설팅 진행",
    news_summary:
      "앰비언스가 제주도 엠버 리조트 신축 프로젝트의 조명 설계 및 납품, 시공에 이르기까지 수주하였습니다.",
    news_content: `
    [아시아타임즈=이하나 기자] 조명 설계 전문업체 앰비언스가 제주도 엠버 리조트 프로젝트 신축 조명 설계 및 컨실팅을 담당, 진행했다고 26일 밝혔다. 
 
    앰비언스가 설계를 진행한 엠버 리조트의 조명은 사람의 눈에 직접적인 영향을 주지 않고 간접적으로 아름다운 빛을 표현해 부담스럽지 않고 휴양지와 제주도 그리고 리조트의 특색에 맞게 즐길 수 있고, 온화하고 편안한 공간으로 느낄 수 있도록 돕는다.

    특히 리조트의 설계를 넘어 납품, 시공에 이르기까지 조명에 관련한 전반적인 서비스와 함께 납품권과 시공권까지 모두 수주해 조명에 관련된 토탈 솔루션을 제안하고 작업했다. 

    리조트를 이용하는 고객의 목적은 다양하지만, 그 중에서도 휴식을 최우선으로 생각하는 경우가 많다. 조명을 설계할 때에도 이러한 부분을 꼼꼼히 파악한 뒤 목적에 벗어나지 않는 조명 토탈 솔루션을 진행해야 한다는게 앰비언스의 설명이다. 

    앰비언스 관계자는 “내부 조명과 함께 전반적인 조명까지 꼼꼼하게 설계, 진행해야 인테리어와 조명의 조화를 올바르게 이어갈 수 있으며 조화로운 휴식의 공간을 연출할 수 있다”고 말했다.`,
    news_date: "2021/09/07 13:05",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_XvUZjSc0_5429ee4cf38fd8d332a6dade9536ac322428a430.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "https://www.asiatime.co.kr/1065579089964776?1=1",
  },
  {
    news_no: 10,
    news_title: "[아시아 타임즈] 앰비언스 '한국해양대학교 도서관' 조명설계",
    news_summary:
      "포인트 조명은 두 가지 색온도의 바리솔 조명으로, 창의적인 활동이 가능할 수 있도록 유도했으며 높이감을 형성해 공간을 넓어보이게 연출했습니다.",
    news_content: `
    [아시아타임즈=이하나 기자] 조명설계 전문 업체 앰비언스가 한국해양대학교 도서관 조명 설계를 진행했다고 23일 밝혔다.

    한국해양대학교 도서관 입구에 눈에 띄는 앵커 라운지는 하나의 공간을 두 가지의 섹션으로 나뉘어 다른 분위기를 제공한다. 포인트 조명은 두 가지 컬러의 바리솔 조명으로 설계해 창의적인 활동이 가능할 수 있도록 유도했으며 높이 감을 형성해 공간을 넓어 보이게 연출했다. 반대편에는 상반된 주광색 조명을 주로 사용해 더욱 더 학업에 집중하고 오랜 시간 공부해도 눈에 부담을 주지 않는 조명을 선택했다고 업체 측은 설명했다.

    앰비언스는 건축 및 건설 시장에서 조명의 기획 단계부터 실시설계, 납품, 시공, 감리에 이르는 전반적인 토털 솔루션을 제안하고 있다. 합리적인 가격에 높은 퀄리티의 조명 컨설팅 서비스를 제안하는 것을 목표로, 학교뿐만 아니라 건물(빌딩)의 내외부 경관조명, 호텔 및 리조트 조명, 병원, 카페, 주거, 매장 조명 등 전반적인 조명 토탈 솔루션을 진행한다.

    앰비언스 관계자는 “공간의 목적이나 분위기를 파악해 그에 적합한 조명 컨설팅을 진행하는 것이 먼저 이루어져야 한다"며 "조명에 따라 분위기와 그 목적이 달리 되는 만큼 공간에 맞는 꼼꼼한 설계와 상담, 그리고 시뮬레이션을 통해 디테일을 확인할 수 있는 업체를 선정해야 한다”고 말했다.`,
    news_date: "2021/09/07 13:12",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_ShwnBlf6_379ff1bdf3d416333bd5a7013de29655a25d03e8.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "https://www.asiatime.co.kr/1065577186282705?1=1",
  },
  {
    news_no: 11,
    news_title: "라이팅뉴스 2020년 8월호",
    news_summary:
      "앰비언스, 제주 엠버리조트 조명설계 / 설계·납품·시공 등 토털솔루션 제안 및 진행",
    news_content: ``,
    news_date: "2021/09/07 13:05",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_Lm4GCgTR_6e402a84b7da3b6ddca6dd1abc05242e767001ff.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "",
  },
  {
    news_no: 12,
    news_title:
      "[중앙일보] 조명디자인 회사 앰비언스, '신라스테이 서부산' 조명 설계",
    news_summary:
      "조명디자인 회사 앰비언스가 부산 서부 지역의 새로운 랜드마크가 될 신라스테이 서부산의 전반적인 조명 설계부터 납품, 시공을 진행했습니다. ",
    news_content: `
    조명디자인 회사 앰비언스가 부산 서부 지역의 새로운 랜드마크가 될 신라스테이 서부산의 전반적인 조명 설계부터 납품, 시공을 진행했다.  
 
    4월 15일 오픈한 신라스테이 서부산은 부산의 비즈니스 관광, 여가의 중심지, 미래 첨단산업도시인 서부산에 위치한 호텔 신라의 프리미엄 비즈니스호텔이다. 부산 서부 지역을 대표하는 명지국제신도시에 위치해 있어 김해∙창원∙거제 등 인접 지역에서 접근이 용이한 최적의 입지를 자랑한다.
 
    앰비언스는 신라스테이 서부산의 295개 객실, 카페, 라운지 & 바, 수영장, 사우나, 피트니스센터, 연회장, 미팅룸 등에 사용된 조명의 설계, 납품, 시공을 완료했다. 고객이 머무는 시간에 상관없이 호텔을 이용하는 모든 고객이 편안하고 차분한 느낌을 받을 수 있도록 따뜻한 색 온도의 조명을 사용하였다. 특히 객실 내부에는 플로어 스탠트 조명과 펜던트 조명을 적용하여 고객이 조명 환경을 다양하게 즐길 수 있도록 했다.
 
    2012년에 창립한 조명 3D시뮬레이션 및 설계 컨설팅 전문회사인 앰비언스는 조명의 기획부터 실시설계, 납품, 시공, 감리까지 조명과 관련된 전반적인 솔루션을 제안하고 있다. 앰비언스는 현재 합리적인 가격으로 높은 퀄리티의 조명 컨설팅 서비스를 제안하는 것을 목표로, 건물(빌딩) 내 외부 경관 조명, 호텔/리조트 조명, 학교, 병원, 카페, 주거, 매장 조명 등 다양한 건물에 대한 조명 토탈 솔루션을 진행 중이다.  
 
    앰비언스 김대웅 대표는 “호텔은 고객들이 충분한 휴식과 여가를 즐길 수 있도록 편안함과 안정감을 주는 조명 제품들을 활용해야 한다”며 “어떤 조명을 선택하느냐에 따라 공간의 분위기가 확 달라질 수 있으므로 꼼꼼한 설계, 상담, 시뮬레이션을 통해 세세한 부분까지 확인할 수 있는 업체를 선정하는 것이 중요하다”고 전했다.`,
    news_date: "2021/09/07 13:14",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667564438_TVhb9OAq_b6163363e9a4e676fcac599891dc35d99a08cdb5.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link: "https://www.joongang.co.kr/article/24036652#home",
  },
  {
    news_no: 13,
    news_title:
      "[이투뉴스] 앰비언스-부산시건축사회 빛공해 방지 경관 조명 설계 업무 협약 체결",
    news_summary:
      "앰비언스가 부산광역시건축사회와 빛공해 방지를 위한 조명설계 솔루션을 위해 힘을 모은다.",
    news_content: `
    앰비언스가 부산광역시건축사회와 빛공해 방지를 위한 조명설계 솔루션을 위해 힘을 모은다.

    지난 12월 8일 앰비언스와 대한건축사협회 부산광역시건축사회는 ‘빛공해 방지 업무 지원을 위한 협약’을 체결했다. 이번 업무 협약은 인공조명으로부터 발생하는 과도한 빛 방사 등의 환경 위해를 방지하고, 시민의 건강과 쾌적한 건축 환경 구현을 위해 상호 협력체계를 구축하는데 목적이 있다.
    
    주요 협약 내용은 빛공해방지 관련 법 제·개정 및 개선에 대한 상호정보 교류, 기술교육훈련 지원 및 운영지원, 빛공해방지 관련 업무에 대한 회원 자문 활동, 빛공해방지 관련 내용 등 홍보 등이 포함됐다. 나아가 앰비언스는 도시 미관을 위해 무분별하게 설치된 인공조명으로 인해 발생하는 빛공해 방지와 인체의 건강, 생태계 보호 등 환경 친화적인 목적을 바탕으로 조명환경관리구역을 체계적으로 관리할 수 있는 조명설계 솔루션을 제공하는데 주안점을 두고 있다.
    
    국내에서는 2012년 2월 인공조명에 의한 빛공해 방지법이 제정되었으며, 법률에 의한 용도지역에 따라 빛공해가 발생하거나 우려가 있는 지역을 제1~4종으로 조명환경관리구역으로 구분하고 있다. 인공조명에 의한 빛공해 방지법에 해당되는 조명의 형태는 공간조명(가로등, 보안등, 공원등, 옥외 체육공간), 광고조명(옥외광고물법 제3조 허가대상 광고물), 장식조명(5층이상 또는 연면적 2,000㎡ 이상, 숙박시설, 위락시설 등) 등 3종으로 구성되어 있다.
    
    앰비언스 김대웅 대표는 “부산광역시건축사회와 적극적인 업무협약을 통해 빛공해 방지 조명설계를 선도해나가는 기업으로 자리매김하고 있다”면서 “앰비언스만의 체계적인 조명 컨설팅 포트폴리오를 바탕으로 빛공해 방지를 위한 조명 설계 및 개선에 참여하고자 하는 기업들에게 현실적인 솔루션을 제공하겠다”라고 전했다.
    
    조명 설계 전문 업체 앰비언스(AMBIENCE)는 합리적인 가격에 높은 퀄리티의 조명 컨설팅 서비스를 제안하는 것을 목표로 건물(빌딩)의 내외부 경관조명, 호텔 및 리조트 조명, 병원, 카페 및 주거, 매장 전시 조명의 설계부터 납품, 시공까지 조명과 관련된 전반적인 조명 토탈 솔루션을 제공하고 있다.`,
    // 저작권자 © ::: 글로벌 녹색성장 미디어 - 이투뉴스 무단전재 및 재배포 금지
    news_date: "2021/12/10 13:36",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667548379_2SVdDsmE_95a0e1c44e030ba2c8fe030b6f308a71a7e81adf.jpg"),
    news_references_img: [
      require("@/assets/images/news_images/references/20211210133630_FCNUQoSxOdD5InqaScm6PoqCjI7AMD.jpg"),
    ],
    news_references_description: [
      "앰비언스-부산시건축사회 빛공해 방지 경관 조명 설계 업무 협약 체결",
    ],
    news_link: "http://www.e2news.com/news/articleView.html?idxno=238118",
  },
  {
    news_no: 14,
    news_title:
      "[비지니스코리아] 앰비언스, 메르세데스 벤츠 스타자동차 전시장 조명 설계",
    news_summary:
      "스타자동차(주)의 사직 오토랜드 매장 조명 설계와 납품을 진행했습니다.",
    news_content: `
    [비지니스코리아=정석이 기자] 앰비언스가 메르세데스-벤츠 공식 딜러 코리아 스타자동차㈜의 사직 오토랜드 매장 조명 설계와 납품을 진행했다고 8일 밝혔다.

    이번 프로젝트는 H디자인그룹과 긴밀한 협업을 통해 메르세데스-벤츠 브랜드 가치에 걸맞은 조명 설계로 완성도를 높였다. 새롭게 오픈한 메르세데스-벤츠 스타자동차㈜ 사직 오토랜드 전시장은 198가지 엄격한 품질 기준에 맞춘 다양한 차량과 정보를 제공하고 있는 공간이다.


    앰비언스는 스타자동차㈜의 사직 오토랜드 인증 차량 전시장부터 상담실까지 메르세데스-벤츠 코리아의 규격을 만족하기 위해 철저한 기구 사양 선정 및 조도 계산을 수행했다. 설치 후 조도 측정을 통한 검수 과정까지 진행하며 공간에 적합한 조명 환경을 제공했다. 앰비언스의 관계자는 “차량 전시장의 경우 실물을 직접 보고 체험해보는 것이 특히나 중요한 분야로 원활한 차량 관람과 상담이 진행에 도움될 수 있도록 에이치디자인그룹과 협업해 설계를 진행했다”라며, “매장 방문 고객을 위한 공간인 만큼 적절한 조도를 확보하여 전시장의 차가 돋보이는 동시에 너무 눈부시지 않은 조명설계에 주목했다”라고 강조했다.

    조명 설계 전문 업체 앰비언스(AMBIENCE)는 합리적인 가격에 높은 퀄리티의 조명 컨설팅 서비스를 제안하는 것을 목표로 건물(빌딩)의 내외부 경관조명, 호텔 및 리조트 조명, 병원, 카페 및 주거, 매장 전시 조명의 설계부터 납품, 시공까지 조명과 관련된 전반적인 조명 토탈 솔루션을 제공하고 있다. 한편, 에이치디자인그룹은 일상에 자연스럽게 스며들어 가치 있는 삶을 선사하고자 귀를 기울이고 소통하여 H만의 디테일을 담은 공간을 연구하는 디자인 스튜디오다.

    저작권자 © Businesskorea`,
    news_date: "2021/11/09 10:41",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667548379_M0X1BlHv_308ee1d5d60f78f2becafbbf4432710fa95442c3.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link:
      "https://www.businesskorea.co.kr/news/articleView.html?idxno=80638",
  },
  {
    news_no: 15,
    news_title:
      "[비즈월드] 앰비언스, 부산시건축사회와 빛공해 예방 조명설계 솔루션 위한 업무협약 체결",
    news_summary:
      "건축 조명 설계 전문 회사 앰비언스가 대한 건축사협회 부산광역시건축사회와 협력을 통해 빛공해 방지를 위한 조명설계 솔루션 선도에 나선다.",
    news_content: `
    [비즈월드] 건축 조명 설계 전문 회사 ‘앰비언스’가 대한건축사협회 부산광역시건축사회와 협력을 통해 빛공해 방지를 위한 조명설계 솔루션 선도에 나선다.

    앰비언스는 지난 8일 부산광역시건축사회와 함께 인공조명으로부터 발생하는 과도한 빛 방사 등의 환경 위해를 방지하고, 시민의 건강과 쾌적한 건축 환경 구현을 위한 업무협약 체결을 했다고 10일 밝혔다. 업무 협약 체결을 통해 ▲빛공해 방지 관련 법 제·개정 및 개선에 대한 상호정보 교류 ▲기술교육훈련 지원 및 운영지원 ▲빛공해 방지 관련 업무에 대한 회원 자문 활동 ▲빛공해 방지 관련 내용 등 홍보 등을 진행하여 빛공해 예방·관리를 위한 조명 설계를 이끌어 나갈 계획이다.
    
    빛공해는 수면장애, 생체리듬교란, 눈부심 등을 유발해 관련 민원도 늘어가는 추세에 있다. 국내에서는 지난 2012년 2월 인공조명에 의한 빛공해 방지법이 제정되었으며, 법률에 의한 용도지역에 따라 빛공해가 발생하거나 우려가 있는 지역을 제1~4종으로 조명환경관리구역으로 구분하고 있다.
    
    인공조명에 의한 빛공해 방지법에 해당되는 조명의 형태는 공간조명(가로등, 보안등, 공원등, 옥외 체육공간), 광고조명(옥외광고물법 제3조 허가대상 광고물), 장식조명(5층 이상 또는 연면적 2000㎡ 이상, 숙박시설, 위락시설 등) 등 3종으로 구성되어 있다.
    
    앰비언스는 빛공해방지법에 따라 공간조명, 광고조명, 장식조명 등 조명 용도별로 빛방사허용기준에 따라 조도, 휘도, 상향광을 조명의 특성 및 사용 목적을 고려하여 맞춤형 조명 설계 추진의 구심체 역할을 수행하게 된다.
    
    김대웅 앰비언스 대표는 “빛공해 방지 조명설계로 각광받고 있는 앰비언스의 차별화된 조명 컨설팅을 기반으로 조명설계 및 개선에 참여하고자 하는 기업들에게 현실적인 솔루션을 제공하겠다”면서 “이를 통해 시민 불편 해소는 물론 건강하고 친환경적인 야간 경관을 조성해 나가겠다”라고 강조했다.`,
    news_date: "2021/12/10 13:43",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667548379_teW153sr_f72cb47978286e917a8444e4175d072680e9423b.jpg"),
    news_references_img: [
      require("@/assets/images/news_images/references/20211210134332_1j1yUgDZuA4dsaAkhV6x4HJCvwsJzi.jpg"),
    ],
    news_references_description: [
      "앰비언스, 부산시건축사회와 빛공해 예방 조명설계 솔루션 위한 업무협약 체결",
    ],
    news_link: "http://www.bizwnews.com/news/articleView.html?idxno=30343",
  },
  {
    news_no: 16,
    news_title:
      "[더드라이브] 전문직 업종, 이미지 메이킹 전략…사업장 조명 설계 눈길",
    news_summary:
      "개인 사무실이나 법인을 운영하고 있는 변호사, 공인중개사 혹은 개인 의원을 운영 중인 의사 등 맨파워가 중요시되고 있는 전문직 업종에서 이미지 메이킹이 화두로 급부상하고 있다.",
    news_content: `
    개인 사무실이나 법인을 운영하고 있는 변호사, 공인중개사 혹은 개인 의원을 운영하고 있는 의사 등 맨파워가 중요시되고 있는 전문직 업종에서 이미지 메이킹이 화두로 급부상하고 있다. 고객과의 관계를 맺고 형성해 나가는 과정에서 이미지 메이킹의 역할이 커지고 있으며, 이의 성패가 매출로 직결되기 때문이다. 이런 사회적 흐름의 예로 회사를 대표하는 인물의 커리어부터 옷차림, 스타일, 태도, 말투와 목소리 톤 등으로 예를 들 수 있는 퍼스널 이미지 메이킹이 대표적이다.
    
    하지만 사회적 관계가 더욱 복잡해지고, 개인이 운영하는 전문직 사업자가 많아지면서 인물에 초점을 둔 이미지 메이킹조차 점차 변별력을 잃어가고 있다는 시각이 지배적이다. 이런 변화 때문에 많은 전문직 개인 사업자가 새로운 이미지 메이킹 수단을 찾고 있는데, 이중 새롭게 떠오르는 것이 바로 경관조명이다.
    
    경관조명은 경치를 비춰 주는 조명으로 주로 사람들이 많이 몰리고 오가는 관광지나 도심의 광장, 건물, 큰 다리 등에 설치하는 조명을 의미한다. 대표 인물에 대한 PR, 사업장 내부의 인테리어를 넘어서 이제는 건물 밖의 유동인구에게 보이는 모습까지도 이미지 메이킹의 수단으로 떠오르고 있는 것이다.
     
    이런 추세를 빠르게 파악하고 사업장에 적용하는 사례가 늘고 있는데, 한 가지 좋은 예로 부산 금정구 소재의 지동범 동물 메디컬 센터가 있다. 빌딩 2층에 위치한 이 병원은 현재 국내에 최초로 도입된 의료 장비를 유일하게 보유한 대형 동물 메디컬 센터이다. 건물 경관에 붓으로 그린 듯한 동그란 원을 표현했는데 이는 동물의 눈을 표현한 것으로 동물 안과 전문 병원이라는 의미를 담고 있다. 
    
    지동범 동물 메디컬 센터의 경관조명 설계에 참여한 조명설계 전문업체 앰비언스의 담당자는 “최고에 걸맞게 센터의 아이덴티티를 조명으로 표현하기 위해 설계에 심혈을 기울였다”고 말했다. 이에 덧붙여 “경관조명을 완성하는데는 제품의 선택 뿐만 아니라 빛의 형태와 성질까지도 고려하여 세심하게 설계해야 원하는 비주얼을 얻을 수 있다”고 말했다.`,
    news_date: "2022/05/27 03:50",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667548379_RenDWhE8_462ebc98685f0f2b7e27f0f03b2cbe6936930b3a.jpg"),
    news_references_img: [],
    news_references_description: [],
    news_link:
      "https://www.thedrive.co.kr/news/newsview.php?ncode=1065587035496394",
  },
  {
    news_no: 17,
    news_title:
      "[서울경제] 앰비언스, 소음 흡수 솔루션 인테리어 조명 부산국회도서관 공급",
    news_summary:
      "앰비언스는 공공기관인 부산국회도서관의 조명 공급업체로 선정돼 다양한 조명 기구 납품 및 시공을 진행했다.",
    news_content: `
    앰비언스는 2012년에 설립된 조명 설계, 컨설팅 전문 기업으로 3D 모델링을 사용한 실제와 같은 조명 시뮬레이션을 통해 전문적인 통합 솔루션을 제공하고 있다. 이런 서비스에는 컨셉에 맞는 조명의 기획부터 설계, 납품, 시공 및 감리까지 모두 포함하고 있어 건축/건설 시장에서 높은 지명도를 보유하고 있다.

    최근 앰비언스는 공공기관인 부산국회도서관의 조명 공급업체로 선정돼 다양한 조명 기구 납품 및 시공을 진행했다. 자료실, 열람실, 로비, 어린이도서관, 복도 등 다양한 공간의 성격에 부합하는 조명을 제안한 것이 공급업체로 선정되는데 주요하게 작용한 것으로 보인다. 
    
    부산국회도서관에 공급과 시공을 진행한 조명들 중 특히 주목할 만한 제품은 이탈리아의 하이엔드 조명 브랜드 루체플랜(LUCEPLAN)의 페탈레(Pétale)가 있다. 이 조명이 특별한 이유는 공간에 난반사되는 소음을 일정 부분 흡수하는 기능을 보유했기 때문이다. 다른 상업공간과는 달리 정숙한 분위기의 유지가 중요한 도서관에 적합한 기능으로 1층 종합 자료실의 가장 큰 테이블 위에 총 7대, 2층 주제 자료실 특화자료 코너에 1대가 설치됐다.
    
    페탈레 펜던트조명은 루체플랜의 Acoustic 라인업의 주요 기능인 소음 흡수 효과뿐만 아니라 빛 색상 변환, 밝기 조절 등 다양한 기능을 보유하고 있는데 부산국회도서관에 공급된 제품은 집중력을 유지에 도움이 되며, 눈의 피로감을 줄일 수 있는 빛 색상과 밝기로 설정된 제품이 적용됐다는 점에서 앰비언스가 가진 조명 설계 노하우를 엿볼 수 있다.
    
    앞서 언급한 루체플랜의 페탈레 외에도 앰비언스는 직접 핸들링하고 있는 유럽 하이엔드 조명 브랜드의 제품들을 도서관 인테리어에 적용했다. 덴마크의 베르판과 루이스폴센, 구비 등 국내 인테리어 업계에도 저명한 브랜드의 기구들을 공간 적재적소에 배치해 인테리어적 완성도를 높였다.
    
    앰비언스의 관계자는 “시민들이 편하게 이용할 수 있는 시설인 만큼 독서와 자료 열람을 위해 오래 머물러도 편안함을 느낄 수 있는 빛을 구현하기 위해 최선을 다했다”라고 말했다. 덧붙어 “채용된 조명 기구들이 국내 인테리어 업계와 소비자에게도 지명도가 높기 때문에 하이엔드 브랜드 조명의 실물과 연출된 인테리어가 궁금한 사람들도 한 번쯤 방문해 보면 좋을 것.”이라고 전했다.
    
    한편, 부산국회도서관은 지식과 문화의 지역 균형 발전을 도모하기 위해 22년 3월 개관해 양질의 지식 정보 서비스와 전시, 독서문화 프로그램 등을 제공하는 복합문화공간으로 거듭나고 있다.`,
    news_date: "2022/05/27 03:57",
    news_thumbs_url: require("@/assets/images/news_images/thumbs/3667548379_05O7Zek3_e27d5795fc64b3368782f6ce2b085b53dd49f7b7.jpg"),
    news_references_img: [
      require("@/assets/images/news_images/references/26606BJ8VB_1.jpg"),
    ],
    news_references_description: [],
    news_link: "https://www.sedaily.com/NewsView/26606BJ8VB",
  },
];
