import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainPage from "../components/main/MainPage.vue";
import WorkMainPage from "../components/work/WorkMainPage.vue";
import ShowRoomMainPage from "../components/showroom/ShowRoomMainPage.vue";
import AboutMainPage from "../components/about/AboutMainPage.vue";
import NewsMainPage from "../components/news/NewsMainPage.vue";
import ContactMainPage from "../components/contact/ContactMainPage.vue";
import WorkSelectOnePage from "../components/work/WorkSelectOnePage.vue";
import NewsSelectOnePage from "../components/news/NewsSelectOnePage.vue";
import PolicyPage from "../components/doc/PolicyPage.vue";
import PrivatePage from "../components/doc/PrivatePage.vue";
import AntiEmailPage from "../components/doc/AntiEmailPage.vue";
import InfoPage from "../components/page_location/InfoPage.vue";
import AblackAppPage from "../components/page_location/AblackAppPage.vue";
import SoleilnoirAppPage from "../components/page_location/SoleilnoirAppPage.vue";
import AmbienceBlogPage from "../components/page_location/AmbienceBlogPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: MainPage,
  },
  {
    path: "/work",
    name: "work",
    component: WorkMainPage,
  },
  {
    path: "/work_one",
    name: "work_one",
    component: WorkSelectOnePage,
  },
  {
    path: "/showroom",
    name: "showroom",
    component: ShowRoomMainPage,
  },
  {
    path: "/about",
    name: "about",
    component: AboutMainPage,
  },
  {
    path: "/news",
    name: "news",
    component: NewsMainPage,
  },
  {
    path: "/news_one",
    name: "news_one",
    component: NewsSelectOnePage,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactMainPage,
  },
  {
    path: "/doc/policy",
    name: "policy",
    component: PolicyPage,
  },
  {
    path: "/doc/private",
    name: "private",
    component: PrivatePage,
  },
  {
    path: "/doc/antiemail",
    name: "antiemail",
    component: AntiEmailPage,
  },
  {
    path: "/info",
    name: "info",
    component: InfoPage,
  },
  {
    path: "/ablack_app",
    name: "ablack_app",
    component: AblackAppPage,
  },
  {
    path: "/soleilnoir_app",
    name: "soleilnoir_app",
    component: SoleilnoirAppPage,
  },
  {
    path: "/ambience_blog",
    name: "ambience_blog",
    component: AmbienceBlogPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
