<template>
  <div class="nav-container">
    <nav>
      <router-link to="/" class="main-logo">
        <div
          :ref="
            (btn) => {
              nav[0] = btn;
            }
          "
          @click="handleActive(0)"
        >
          <img
            v-if="state.currentParams !== 'main'"
            src="../src/assets/images/logo/ambience_symbol.png"
          />
          <img v-else src="../src/assets/images/logo/app-logow.png" />
        </div>
      </router-link>
      <router-link to="/work">
        <div
          :ref="
            (btn) => {
              nav[1] = btn;
            }
          "
          class="nav-item-box"
          :class="{
            main: state.currentParams === 'main',
            inactive: state.currentNav !== 1,
          }"
          @click="handleActive(1)"
        >
          <p>WORKS</p>
          <div class="bottom-line"></div>
        </div>
      </router-link>
      <!-- <router-link to="/showroom">
        <div
          :ref="
            (btn) => {
              nav[2] = btn;
            }
          "
          class="nav-item-box"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 2,
          }"
          @click="handleActive(2)"
        >
          <p>SHOW ROOMS</p>
          <div class="bottom-line"></div>
        </div>
      </router-link> -->
      <router-link to="/about">
        <div
          :ref="
            (btn) => {
              nav[3] = btn;
            }
          "
          class="nav-item-box"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 3,
          }"
          @click="handleActive(3)"
        >
          <p>ABOUT</p>
          <div class="bottom-line"></div>
        </div>
      </router-link>
      <router-link to="/news">
        <div
          :ref="
            (btn) => {
              nav[4] = btn;
            }
          "
          class="nav-item-box"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 4,
          }"
          @click="handleActive(4)"
        >
          <p>NEWS</p>
          <div class="bottom-line"></div>
        </div>
      </router-link>
      <router-link to="/contact">
        <div
          :ref="
            (btn) => {
              nav[5] = btn;
            }
          "
          class="nav-item-box"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 5,
          }"
          @click="handleActive(5)"
        >
          <p>CONTACT</p>
          <div class="bottom-line"></div>
        </div>
      </router-link>
    </nav>
  </div>
  <!-- 모바일 반응형 메뉴 버튼 -->
  <div
    v-if="state.currentParams === 'main'"
    class="mobile-nav"
    @click="handleMobileMenu()"
  >
    <img src="../src/assets/images/logo/app-logow.png" />
  </div>
  <div
    v-if="state.currentParams !== 'main'"
    class="mobile-nav"
    @click="handleMobileMenu()"
  >
    <img src="../src/assets/images/logo/ambience_symbol.png" />
  </div>
  <!-- 모바일 반응형 메뉴 -->
  <div
    v-if="state.mobileMenu"
    class="mobile-menu-background"
    @click="closeMobileMenu()"
  >
    <div class="mobile-menu-container">
      <router-link to="/">
        <div
          :ref="
            (btn) => {
              nav[0] = btn;
            }
          "
          class="mobile-menu-button"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 0,
          }"
          @click="handleActive(0)"
        >
          MAIN
        </div>
      </router-link>
      <router-link to="/work">
        <div
          :ref="
            (btn) => {
              nav[1] = btn;
            }
          "
          class="mobile-menu-button"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 1,
          }"
          @click="handleActive(1)"
        >
          WORKS
        </div>
      </router-link>
      <router-link to="/about">
        <div
          :ref="
            (btn) => {
              nav[3] = btn;
            }
          "
          class="mobile-menu-button"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 3,
          }"
          @click="handleActive(3)"
        >
          ABOUT
        </div>
      </router-link>
      <router-link to="/news">
        <div
          :ref="
            (btn) => {
              nav[4] = btn;
            }
          "
          class="mobile-menu-button"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 4,
          }"
          @click="handleActive(4)"
        >
          NEWS
        </div>
      </router-link>
      <router-link to="/contact">
        <div
          :ref="
            (btn) => {
              nav[5] = btn;
            }
          "
          class="mobile-menu-button"
          :class="{
            main: state.currentParams == 'main',
            inactive: state.currentNav !== 5,
          }"
          @click="handleActive(5)"
        >
          CONTACT
        </div>
      </router-link>
    </div>
  </div>
  <div class="view-container">
    <div></div>
    <div class="view-main">
      <router-view :key="$route.fullPath" />
    </div>
    <div></div>
  </div>
  <div class="footer" v-if="state.currentParams !== 'main'">
    <FooterPage />
  </div>
</template>

<script>
import FooterPage from "./components/layout/FooterPage.vue";
import { reactive, ref, toRefs } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";
export default {
  components: {
    FooterPage,
  },
  setup() {
    const nav = ref([]);
    const route = useRouter();
    const state = reactive({
      currentNav: 0,
      currentParams: "main",
      mobileMenu: false,
    });
    const handleActive = (num) => {
      state.currentNav = num;
    };
    const handleMobileMenu = () => {
      state.mobileMenu = true;
    };
    const closeMobileMenu = () => {
      state.mobileMenu = false;
    };
    watch(route.currentRoute, () => {
      state.currentParams = route.currentRoute.value.name;
      if (state.currentParams == "main") {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    });

    watch(
      () => state.mobileMenu,
      () => {
        const pageY = window.pageYOffset;
        if (state.mobileMenu === true) {
          document.body.setAttribute("scrollY", pageY.toString());
          document.body.style.overflow = "hidden";
          document.body.style.position = "fixed";
          document.body.style.left = "0px";
          document.body.style.right = "0px";
          document.body.style.bottom = "0px";
          document.body.style.top = `-${pageY}px`;
        } else {
          document.body.style.overflow = "auto";
          document.body.style.removeProperty("position");
          document.body.style.removeProperty("left");
          document.body.style.removeProperty("right");
          document.body.style.removeProperty("bottom");
          document.body.style.removeProperty("top");
          window.scrollTo(0, Number(document.body.getAttribute("scrollY")));
          document.body.removeAttribute("scrollY");
        }
      }
    );

    return {
      state,
      handleActive,
      handleMobileMenu,
      nav,
      closeMobileMenu,
      ...toRefs(state),
    };
  },
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");
#app {
  font-family: "pretendard", Tahoma, Arial, Helvetica, sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.nav-container {
  position: absolute;
  top: 40px;
  z-index: 100;
  display: flex;
  justify-content: start;
  width: 100%;
}

nav {
  padding: 20px;
  display: flex;
  text-align: center;
}

.nav-item-box {
  display: inline-block;
  position: relative;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

nav a {
  font-weight: bold;
  color: #000000;
}

.main-logo {
  margin-right: 20px;
}

.bottom-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2.4px solid #000000;
  animation-direction: reverse;
}

nav .nav-item-box .bottom-line {
  width: 0px;
}

/* nav .nav-item-box:hover:not(.router-link-active) .bottom-line {
  width: 100px;
  animation: onBottomLine 0.3s ease;
} */

/* nav a.router-link-exact-active .nav-item-box {
} */

nav a.router-link-exact-active .bottom-line {
  width: 80px;
}

nav .inactive:hover .bottom-line {
  width: 80px;
  animation: onBottomLine 0.3s ease;
}

.main p {
  color: #ffffff;
}

.main .bottom-line {
  border-color: #ffffff;
}

img {
  width: 50px;
  height: 50px;
}

.view-container {
  display: flex;
  justify-content: center;
}

.view-main {
  width: 1400px;
}

.footer {
  width: 100%;
  /* background-color: #cfcdc9; */
}

@keyframes onBottomLine {
  from {
    width: 0;
  }
  to {
    width: 80px;
  }
}

@keyframes offBottomLine {
  from {
    width: 80px;
  }
  to {
    width: 0;
  }
}

.mobile-nav {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  color: #fff;
  display: none;
}
.mobile-menu-background {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 104;
  background-color: rgba(0, 0, 0, 0.4);
}

.mobile-menu-container {
  width: 50%;
  z-index: 105;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateX(100%);
  animation: mobileNavSlide 0.4s ease-in-out;
  padding-top: 100px;
}
@keyframes mobileNavSlide {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(100%);
  }
}

.mobile-menu-button {
  color: #fff;
  text-align: right;
  padding: 30px 15px 0 0;
  font-size: 24px;
  font-weight: 800;
}

@media (max-width: 600px) {
  .view-container {
    width: 100%;
    overflow-x: hidden;
  }
  .nav-container {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .mobile-menu-container a {
    text-decoration: none;
  }
}
</style>
