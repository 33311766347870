<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="doc">
        <div class="doc-text-box">
          <span>(주)앰비언스</span>
          <span>김대웅</span>
        </div>
        <div class="doc-link-box">
          <span class="doc-link" @click="goDocPage(1)">이메일무단수집거부</span>
          <span>|</span>
          <span class="doc-link" @click="goDocPage(2)"> 개인정보처리방침 </span>
        </div>
      </div>
      <div class="office">
        <div class="office-label">AMBIENCE OFFICE</div>
        <div class="office-info">
          <span
            >48060 ) 부산 해운대구 센텀1로 28 (우동, 더블유비씨더팔레스오피스텔)
            101동 702호 [앰비언스]
          </span>
          <span>대표번호 : 051-558-1317</span>
          <span>팩스 : 051-558-3579</span>
          <span>E-mail : ambience1mt@gmail.com</span>
        </div>
      </div>
      <div class="copy-right">
        <p>COPYRIGHT(C) (주)앰비언스 . CO.LTD ALL RIGHT RESERVED.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "FooterPage",
  setup() {
    const router = useRouter();
    const goDocPage = (cate_number) => {
      if (cate_number === 1) {
        router.push({ name: "antiemail" });
      } else {
        router.push({ name: "private" });
      }
    };
    return {
      goDocPage,
    };
  },
};
</script>

<style scoped>
.footer-container {
  margin-top: 100px;
  padding: 45px 0;
  width: 100%;
  height: 150px;
  background-color: #cfcdc9;
}
.footer-content {
  padding: 0 120px;
}
.doc {
  font-weight: 600;
}
.doc span {
  margin: 0 10px 0 0;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.doc-link {
  font-size: 15px;
  font-weight: 500;
}
.office {
  display: flex;
  justify-content: start;
  margin-top: 16px;
}
.office-label {
  width: 150px;
  padding-right: 60px;
  line-height: 20px;
  color: #555;
  font-size: 14px;
  letter-spacing: -0.025em;
  font-weight: 700;
}
.office-info {
  font-size: 13px;
}
.office-info span {
  padding-right: 30px;
  line-height: 20px;
  /* white-space: nowrap; */
}
.copy-right {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #3d3d3d;
  color: #777777;
  font-size: 13px;
}
.doc-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 600px) {
  .footer-container {
    height: auto;
    padding: 20px 0;
  }
  .footer-content {
    padding: 0 20px;
  }
  .doc {
    display: block;
    line-height: 26px;
  }
  .office {
    display: block;
  }
  .office-info span {
    white-space: normal;
    display: block;
    padding-right: 0;
  }
}
</style>
