<template>
  <div>A.Black 앱 페이지 이동</div>
</template>

<script>
export default {
  setup() {
    window.location.href = "https://b2b.ambience.kr/ablack_app";
  },
};
</script>
