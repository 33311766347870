<template>
  <div class="container">
    <div class="news-header">
      <h4>{{ state.news_one.news_title }}</h4>
      <span>작성일 : {{ state.news_one.news_date }}</span>
    </div>
    <div class="content-box">
      <div v-if="state.news_one.news_references_img[0]" class="reference-1-box">
        <img :src="state.news_one.news_references_img[0]" />
      </div>
      <div v-if="state.news_one.news_references_img[0]" class="reference-1-box">
        <span>[{{ state.news_one.news_references_description[0] }}]</span>
      </div>
      <div class="news-content">
        <p>
          {{ state.news_one.news_content }}
        </p>
      </div>
      <div v-if="state.news_one.news_link" class="link-box">
        <a :href="state.news_one.news_link" target="_black">
          [기사 보러가기]
        </a>
      </div>
    </div>
    <div class="button-box">
      <button @click="goNewsList()" style="background-color: #fff">목록</button>
      <button v-if="state.news_no < state.news_total" @click="goPrevNews()">
        이전
      </button>
      <button v-if="state.news_no > 1" @click="goNextNews()">다음</button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { newsData } from "@/data/newsData";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      news_no: route.query.no,
      news_one: {
        news_title: "",
        news_date: "",
        news_content: "",
        news_link: "",
        news_references_img: [],
        news_references_description: [],
      },
      news_total: newsData.length,
    });

    const goNewsList = () => {
      router.push({ name: "news" });
    };

    const goPrevNews = () => {
      router.push({
        name: "news_one",
        query: { no: Number(route.query.no) + 1 },
      });
    };

    const goNextNews = () => {
      router.push({
        name: "news_one",
        query: { no: Number(route.query.no) - 1 },
      });
    };

    onMounted(() => {
      state.news_one = newsData[route.query.no - 1];
      window.scrollBy(0, 0);
    });

    return {
      state,
      ...toRefs(state),
      goNewsList,
      goPrevNews,
      goNextNews,
    };
  },
};
</script>

<style scoped>
h4,
span {
  margin: 0;
}
.container {
  width: 100%;
  margin-top: 200px;
}
.news-header {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #545c73;
  border-bottom: solid #ddd;
  line-height: 20px;
  padding: 20px;
}
.news-header span {
  color: #666666;
  font-size: 12px;
}
.reference-1-box {
  display: flex;
  justify-content: center;
  width: 100%;
}
.reference-1-box img {
  margin-top: 60px;
  width: 80%;
  max-width: 680px;
  height: auto;
  object-fit: contain;
}
.reference-1-box span {
  margin-top: 10px;
}
.news-content {
  display: flex;
  justify-content: center;
  color: #666666;
  font-size: 15px;
}
.news-content p {
  width: 80%;
  max-width: 680px;
  line-height: 1.4em;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
}
.link-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.link-box a {
  color: #666666;
  text-decoration: none;
  font-size: 18px;
}
.link-box a:hover {
  color: #000;
  font-weight: 600;
}
.button-box {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid #ddd;
}
.button-box button {
  padding: 5px 20px;
  line-height: 20px;
  border: 1px solid #ddd;
  margin-right: 3px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .container {
    margin-top: 80px;
  }
  .news-header {
    display: block;
  }
  .reference-1-box span {
    width: 80%;
    text-align: center;
  }
  .button-box {
    padding-left: 10%;
  }
}
</style>
