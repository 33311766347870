<template>
  <div>앰비언스 블로그 이동</div>
</template>

<script>
export default {
  setup() {
    window.location.href = "https://b2b.ambience.kr/ambience_blog";
  },
};
</script>
